import { Button, Col, Input, Row, Tabs, Form, Radio, Table,List, Image, Switch, Select, Typography, Divider, Space, AutoComplete } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "../mastercss/master.module.css";
import { Prename } from 'src/domain/prenameType';
import { Location } from 'src/domain/locationType';
import { SecUser } from 'src/domain/secUserType';
import PartnerDataInfo from './PartnerDataInfo';
import { RouteComponentProps } from 'react-router';
import PartnerTruckHistoryInfo from './PartnerTruckHistoryInfo';
import PartnerTruckInfo from './PartnerTruckInfo';
import PartnerUserInfo from './PartnerUserInfo';
import PartnerUserView from './PartnerUserView';


interface IParams {
    id: string;
    userId: string;
    username: string;
    station: string;
    partnerUId:string;
}

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

function callback(key: any) {
    console.log(key);
}

const columnPartnerTruck = [
    {
        title: 'ลำดับ',
        dataIndex: 'id',
    },
    {
        title: 'ชนิดรถ',
        dataIndex: 'shortName',
    },
    {
        title: 'ทะเบียนรถ',
        dataIndex: 'tel',
    },
    {
        title: 'พนักงานขับรถ',
        dataIndex: 'taxNo',
    },
    {
        title: 'สถานะ',
        dataIndex: 'taxNo',
    },
   
];

const columnTruckHitorys = [
    {
        title: 'ลำดับ',
        dataIndex: 'id',
    },
    {
        title: 'วันที่ใช้งาน',
        dataIndex: 'shortName',
    },
    {
        title: 'วันที่สิ้นสุดใช้งาน',
        dataIndex: 'tel',
    },
    {
        title: 'เลขที่งาน',
        dataIndex: 'taxNo',
    },
    {
        title: 'ชนิดรถ',
        dataIndex: 'taxNo',
    },
    {
        title: 'ทะเบียนรถ',
        dataIndex: 'taxNo',
    },
];


const PartnerForm : React.FC<RouteComponentProps<IParams>> = (props) => {


    const id = Number(props.match.params.id);
    const partnerUId = props.match.params.partnerUId;
    console.log(partnerUId);
    const userId = Number(99);
    const username = "sittiponn";
    const station = '11';

    const [prenames, setPrenames] = useState<Prename[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [location, setLocation] = useState<Location>({} as Location);
    const [addsPathData, setAddsPathData] = useState<any>();
    const [locationCode, setLocationCode] = useState<any>();
    const [zipCodeData, setzipCodeData] = useState<any>();
    const [secUser, setsecUsers] = useState<SecUser[]>([]);

    const onSelectLocation = (data: any) => {
        console.log("onselect > ", data);
        let arr = data.split("/");
        // console.log(arr[3])

        let rs = locations.filter((item: any) =>
            item.zipcode === arr[3]
            && item.province === arr[2]
            && item.amphoe === arr[1]
            && item.tambon === arr[0])

        setAddsPathData(rs[0].provinceCode + "/" + rs[0].amphoeCode + "/" + rs[0].tambonCode);
        setLocationCode(rs[0].tambonCode);
        setzipCodeData(rs[0].zipcode);

    };

    return (
        <>
            <PageHeader title="เพิ่มข้อมูลเจ้าของรถ"></PageHeader>

            <Content className="app-page-content" >

                <div className={classes.divAdd}>

                    <div style={{
                        padding: "20px",
                        width: "100%",
                    }}>

                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="ข้อมูล" key="1">
                            
                                <PartnerDataInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                    partnerUId ={partnerUId}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                               
                            </TabPane>
                            <TabPane tab="บัญชีผู้ใช้" key="2">
                            <PartnerUserInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                    partnerUId={partnerUId}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                             />
                             {/* <PartnerUserView
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                             /> */}
                            </TabPane>
                            <TabPane tab="ข้อมูลรถ" key="3">
                            <PartnerTruckInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                    partnerUId={partnerUId}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                            </TabPane>
                            <TabPane tab="ข้อมูลการวิ่งรถ" key="4">
                            <PartnerTruckHistoryInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                    partnerUId={partnerUId}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                            </TabPane>
                        </Tabs>
                    </div>

                </div>

            </Content>

        </>
    )
}

export default PartnerForm
