import { Table, Input, Button, Tag, Avatar, Image } from 'antd';
import React, { useState } from 'react'
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from "@ant-design/icons";
import { Content } from 'antd/lib/layout/layout';

import { useHistory } from 'react-router';
import classes from "../owner/master.module.css";

const { Search } = Input;

interface DataType {
  key: React.Key;
  driverName: string;
  tel: string;
  idCardNo: string;
  status?: string[];
  imgOwner?: string
}

const data: DataType[] = [
  {
    key: '1',
    driverName: 'Tim Brown',
    tel: '0878987873',
    idCardNo: '1508899899384',
    status: ['ใช้งาน'],
  },
  {
    key: '2',
    driverName: 'Jim Yel',
    tel: '0878987873',
    idCardNo: '1508899899384',
    status: ['ใช้งาน'],
  },
  {
    key: '3',
    driverName: 'Joe Black',
    tel: '0878987873',
    idCardNo: '1508899899384',
    status: ['ใช้งาน'],
  },
  {
    key: '4',
    driverName: 'Mui Will',
    tel: '0878987873',
    idCardNo: '1508899899384',
    status: ['ไม่ใช้งาน'],
  },
];

const columns = [
  {

    title: '',
    dataIndex: 'ownerName',
    align: 'right' as 'right',
    render: (text: any, record: DataType) => {
      return (
        <>
          <Avatar
            src={<Image src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
          />
        </>
      )
    },

  },
  {

    title: 'ชื่อพนักงานขับรถ',
    dataIndex: 'driverName',

  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'tel',
  },
  {
    title: 'บัตรประชาชน',
    dataIndex: 'idCardNo',
  },

  {
    title: 'สถานะ',
    key: 'status',
    dataIndex: 'status',
    render: (status: any) => (
      <>
        {
          status.map((tag: any) => {
            let color = 'green';
            if (tag === 'ไม่ใช้งาน') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          }

          )
        }
      </>
    ),
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
}

const DriverList = () => {

  const history = useHistory();
  const onSearch = (value: any) => console.log(value);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');

 

  return (
    <>
      <PageHeader title="ข้อมูลพนักงานรถ">

        <Button
          size="small"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/driver/new")}
        >
          เพิ่ม
              </Button>

      </PageHeader>

      <Content className="app-page-content" >

        <div className={classes.divList}>

          <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200 }} />


          <div style={{
            padding: "20px",
            width: "100%",
          }}>

            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={data}
            // columns={columns}
            // dataSource={post}
            // size="small"
            // rowKey={(item: Post) => item.id}
            />
          </div>

        </div>

      </Content>
    </>
  )
}

export default DriverList
