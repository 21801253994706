import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Descriptions, Layout, Space, Col } from "antd";
import PageHeader from "src/layouts/PageHeader";
import { EditOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {dummydata} from "src/views/subject/data";
import { Subject } from "src/domain/subjectType";
import subjectService from "src/services/subjectService";

interface IPrarams {
  id: string;
}


const ViewSubject: React.FC<RouteComponentProps<IPrarams>> = (props) => {
  const [subject, setSubject] = useState<Subject>({} as Subject);
  const history = useHistory();

    // //  console.log(props.match.params.id)
    //  let subjectId:number = Number(props.match.params.id)
    //    let data =  dummydata.filter((item:any) => item.id === subjectId );
    //   //  console.log("data"+data.name)
  
  useEffect(() => {
    let subjectId:number = Number(props.match.params.id)
    let data:any = subjectService.getSubjectById(subjectId);
    setSubject(data)
  }, []);
  return (
    <div>
      <PageHeader
        title={String("Subject : "+subject.name)}
        onClose={() => {
          history.push("/app/subject/list");
        }}
      >
        <Space>

        
        <Button
          size="small"
          // type="primary"
          icon={<EditOutlined />}
          onClick={() => {
            history.push(`/app/subject/edit/${subject.id}`);
          }}
        >
          Edit
        </Button>
        <Button
          size="small"
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            subjectService.deleteSubject(subject.id)
            // history.push(`/app/subject/edit/${subject.id}`);
            history.goBack()
          }}
        >
          Delete
        </Button>
        </Space>
      </PageHeader>
      <Layout.Content className="app-page-content">
        <div style={{ padding: "0 20px" }}>
          <Descriptions
            title="View subject"
            column={2}
            size="middle"
            bordered
          >
            <Descriptions.Item label="Id" span={2}>
              {subject.id}
            </Descriptions.Item>
            <Descriptions.Item label="Name" span={2}>
              {subject.name}
            </Descriptions.Item>
            <Descriptions.Item label="Unit" span={2}>
              {subject.unit}
            </Descriptions.Item>
            <Descriptions.Item label="Level">{subject.level}</Descriptions.Item>
          </Descriptions>
        </div>
      </Layout.Content>
    </div>
  );
};

export default ViewSubject;