 export const fleetUrl:string = 'https://api15.nimsoft.xyz/' //server
 export const securityUrl:string = 'https://api16.nimsoft.xyz/'
 //export const udomitHostUrl:string = 'http://172.16.32.107:8080/' //wifi
// export const udomitHostUrl:string = 'http://172.16.32.20:8080/' //lan

// export const fleetUrl:string = 'http://localhost:8080/' //wifi
//  export const securityUrl:string = 'http://localhost:8083/' //wifi

// export const fleetUrl:string = 'http://172.16.32.20:8080/' //lan
// export const securityUrl:string = 'http://172.16.32.20:8083/' //lan
