import { Button, Col, Input, Row, Tabs, Form, Radio, Table,List, Image, Switch, Select, Typography, Divider, Space, AutoComplete, message, DatePicker } from 'antd'
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import { Prename } from 'src/domain/prenameType';
import { Location } from 'src/domain/locationType';
import patnerService from "src/services/partnerService";
import { Partner } from 'src/domain/partnerType';
import { showMessage } from 'src/utils';
import partnerService from 'src/services/partnerService';
import moment from 'moment';
interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    partnerUId?:any;
    isSave?: any;
}

const { Option } = Select;

const PartnerDataInfo : React.FC<IProps> = (props) => {

    const { id, userId, username, station, isSave,partnerUId } = props;
  //  console.log(station)

    const [prenames, setPrenames] = useState<Prename[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [location, setLocation] = useState<Location>({} as Location);
    const [addsPathData, setAddsPathData] = useState<any>();
    const [locationCode, setLocationCode] = useState<any>();
    const [zipCodeData, setzipCodeData] = useState<any>();
    const [custUIdData, setcustUIdData] = useState<any>();
    const [action, setAction] = useState(false);
    const [partnerTypeValue, setPartnerTypeValue] = useState<any>(0);

    const [partner, setPartner] = useState<Partner>({} as Partner);
    const [form] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY';
    const [isUseflg, setUseflg] = useState<any>(false);
    const [useflgData, setUseflgData] = useState<any>(0);
    

    const radioOnChange = (e:any) => {
        setPartnerTypeValue(e.target.value);
    };
    
    const switchOnChange = (checked:any) => {
        console.log(`switch to ${checked}`);
        setUseflg(checked);
        if(checked === false){
            setUseflgData(-1)
        }else{
            setUseflgData(0)
        }
        console.log(useflgData);
    };


    useEffect(() => {

        patnerService.getPartnerByPartnerUId(partnerUId)
            .then((res) => {
                setPartner(res.data)
            })

        patnerService.getAllPrename().then((res) => {
            setPrenames(res.data)
        })

        patnerService.getLocationAll().then((res) => {
            // console.log(res.data);
            setLocations(res.data)
        })

        console.log(partner);
    }, []);

    useEffect(() => {
        console.log(partner,)
        if (partnerUId === "") {
              console.log(partnerUId)
        } else {
            if(partner.useFlg === 0) {
                setUseflg(true);
                setUseflgData(partner.useFlg)
            }   
            setAction(true);
            setAddsPathData(partner.addrPath);
            setLocationCode(partner.locationCode);
            setzipCodeData(partner.zipcode);
            let effDateData = "";
            let endDateData = "";
            if(partner.effDate !== ""){
                effDateData = moment(partner.effDate).format("YYYY/MM/DD") ;
            }
            if(partner.endDate!== ""){
                endDateData = moment(partner.endDate).format("YYYY/MM/DD") ;
            }
            setPartnerTypeValue(partner.partnerType);

            // console.log(partner.effDate,partner.endDate)

            form.setFieldsValue({
                prenameId:partner.prenameId,
                partnerName:partner.name,
                address:partner.address,
                addrPath:partner.addrPathStr,
                tel:partner.tel,
                cropId:partner.cropId,
                insuranceAmt:partner.insuranceAmt,
                identityId:partner.identityId,   
                effDate: effDateData !== ""? moment(new Date(`${effDateData}`)) :"",
                endDate: endDateData !== ""? moment(new Date(`${endDateData}`)) :"",
                
            });

            console.log(effDateData);
        }

    }, [partner]);

    const onSelectLocation = (data: any) => {
        console.log("onselect > ", data);
        let arr = data.split("/");
        // console.log(arr[3])

        let rs = locations.filter((item: any) =>
            item.zipcode === arr[3]
            && item.province === arr[2]
            && item.amphoe === arr[1]
            && item.tambon === arr[0])

        setAddsPathData(rs[0].provinceCode + "/" + rs[0].amphoeCode + "/" + rs[0].tambonCode);
        setLocationCode(rs[0].tambonCode);
        setzipCodeData(rs[0].zipcode);

    };

    const onFinish = async (values: any) => {

        let effDateData =""; 
        let endDateData ="";
        // console.log("enddate >>> "+values.endDate)
        if(values.effDate !== undefined && values.effDate !==""){
            effDateData = moment(values.effDate).format('YYYYMMDD');
        }

        if(values.endDate !== undefined&& values.endDate !==""){
            endDateData = moment(values.endDate).format('YYYYMMDD');
        }

        const _partner: Partner = {

            id: id,
            partnerUId:partnerUId,
            prenameId:values.prenameId,
            name:values.partnerName,
            address:values.address,
            addrPath:addsPathData,
            tel:values.tel,
            cropId:values.cropId,
            insuranceAmt:values.insuranceAmt,
            identityId:values.identityId,
            partnerType: partnerTypeValue,
            
            locationCode :locationCode,
            zipcode : zipCodeData,

            effDate: effDateData,
            endDate: endDateData,
            useFlg : useflgData,

            userIdLogin: userId,
            usernameLogin: username,
            savStation:station,
            // custUId: custUIdData,

        };

        // console.log(effDateData,endDateData);


        if (partnerUId==="") {


            try {
                const partnerId = partnerService.addPartner(_partner).then(res => {
                    message.success("save success", 1.5);
                    // console.log("customerId >>>>",customerId)
                    isSave(true);
                    // console.log("save " + props.id, props.isSave);
                }).catch(err => {
                    console.log(err);
                });
            } catch (err:any) {
                showMessage(err);
            }


        }

        if (partnerUId !== "") {
            partnerService.updatePartner(_partner).then(res => {
                // history.replace("/app/business/list/");
                message.success("update complete", 1.5);
                // isSave(true);
            }).catch(err => {
                console.log(err)
            });
        }
        // setTrigger(false);
    };


    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <>
           <Form
                                form={form}
                                layout="horizontal"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                // initialValues={{ requiredMarkValue: requiredMark }}
                                // onValuesChange={onRequiredTypeChange}
                                // requiredMark={requiredMark}
                                >
                                    <Row>
                                        <Col span={15}>
                                            <div className={classes.divAdd2}>

                                            <Row gutter={10} style={{ marginTop: "2em", }}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        </Col>
                                                    <Col span={16} >
                                                        <Form.Item
                                                            name="partnerType" >
                                                                {console.log(partnerTypeValue)}
                                                            <Radio.Group onChange={radioOnChange} value={partnerTypeValue}>
                                                                <Radio value={0}>บุคคลทั่วไป</Radio>
                                                                <Radio value={1}>นิติบุคคล</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>

                                                <Row gutter={10} >
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>ชื่อบริษัท : </span> </Col>
                                                    <Col span={5}>
                                                        <Form.Item
                                                            name="prenameId"
                                                            rules={[{ required: true, message: 'เลือกคำนำหน้า' }]}
                                                            required tooltip="This is a required field">
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                            >

                                                                {prenames.map((item) =>
                                                                    <Option value={item.id} key={item.id} label={item.name}>
                                                                        {item.name}
                                                                    </Option>
                                                                )}

                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                    <Col span={10} >
                                                        <Form.Item
                                                            name="partnerName"
                                                            rules={[{ required: true, message: 'กรอกชื่อบริษัท' }]}
                                                            required tooltip="This is a required field">
                                                            <Input placeholder="" />
                                                        </Form.Item>
                                                    </Col>
                                                    {/* <Col span={6}></Col> */}
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>เลขที่ :</span> </Col>
                                                    <Col span={16} >
                                                        <Form.Item
                                                            name="address"
                                                            rules={[{ required: true, message: 'กรอกที่อยุ่' }]}
                                                            required tooltip="This is a required field">
                                                            <Input placeholder="บ้าน/ซอย/ถนน" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>ที่อยู่ :</span> </Col>
                                                    <Col span={16} >
                                                        <Form.Item
                                                            name="addrPath"
                                                            rules={[{ required: true, message: 'เลือกที่อยู่' }]}
                                                            required tooltip="This is a required field">
                                                            <AutoComplete
                                                                onSelect={onSelectLocation}
                                                                // style={{ width: '80%' }}
                                                                filterOption={(input: any, option: any) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษรีย์"
                                                            >
                                                                {locations.map((item) =>
                                                                    <Option
                                                                        value={item.addrPath}
                                                                        key={item.id}
                                                                        label={item.addrPath}>

                                                                        {item.addrPath}

                                                                    </Option>
                                                                )}
                                                            </AutoComplete>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>เบอร์โทร :</span> </Col>
                                                    <Col span={8} >
                                                        <Form.Item
                                                            name="tel"
                                                            rules={[{ max:10, required: true, message: 'กรอกเบอร์โทร' }]}
                                                            required tooltip="This is a required field">
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>เลขบัตรประชาชน :</span> </Col>
                                                    <Col span={8} >
                                                        <Form.Item
                                                            name="identityId"
                                                            rules={[{max:13, required: true, message: 'กรอกเลขบัตรประชาชน'  }]}
                                                            required tooltip="This is a required field">
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>เลขที่นิติบุคคล :</span> </Col>
                                                    <Col span={8} >
                                                        <Form.Item
                                                            name="cropId"
                                                            rules={[{ required: true, message: 'กรอกเลขที่นิติบุคคล' }]}
                                                            required tooltip="This is a required field">
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>วงเงินประกัน :</span> </Col>
                                                    <Col span={12} >
                                                        <Form.Item >
                                                            <Row gutter={10}>
                                                                <Col>
                                                                    <Form.Item
                                                                        name="insuranceAmt"
                                                                        noStyle
                                                                        rules={[{ required: true, message: 'กรอกวงเงินประกัน' }]}
                                                                        required tooltip="This is a required field"
                                                                    >
                                                                        <Input type='number' style={{textAlign:'right'}} />
                                                                    </Form.Item>

                                                                </Col>
                                                                <Col style={{ textAlign: "left", fontSize: "15px" }}>
                                                                    <span>บาท</span>
                                                                </Col>
                                                            </Row>

                                                        </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>

                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>วันที่ใช้งาน :</span> </Col>
                                                    <Col span={8} >
                                                    <Form.Item
                                                     
                                                        name="effDate"  
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <DatePicker 
                                                           format={dateFormat}
                                                        //    onChange={getDataTaxDeate} 
                                                           />
                                                    </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={5} style={{ textAlign: "right", fontSize: "15px" }}>
                                                        <span>วันที่สิ้นสุด :</span> </Col>
                                                    <Col span={8} >
                                                    <Form.Item
                                                       
                                                        name="endDate"  
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <DatePicker 
                                                           format={dateFormat}
                                                        //    onChange={getDataTaxDeate}
                                                            />
                                                    </Form.Item>
                                                    </Col>
                                                    <Col />
                                                </Row>

                                                <Row
                                                //  style={{ marginTop: "1em", marginLeft: "1em" }}
                                                >

                                                    <Col span={19}></Col>
                                                    <Col span={4}>

                                                        <Button
                                                            size="middle"
                                                            type="primary"
                                                            icon={<SaveOutlined />}
                                                        // style={{ marginTop: "1em", marginLeft: "1em" }}
                                                        // shape="round"
                                                         onClick={() => { form.submit() }}
                                                        >
                                                            บันทึก
                                                        </Button>
                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>
                                            </div>

                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={8}>
                                            <div className={classes.divAddImg}
                                            // style={{
                                            //         // border: "1.5px solid #706d6d",
                                            //         borderRadius: "15px",
                                            //         padding: "5px",
                                            //         width: "97%",
                                            //         marginTop: "1em",
                                            //         background: "#fbfcfd",
                                            //     }}
                                            >

                                                <Row className={classes.divAddImg2}
                                                // style={{

                                                //      marginLeft: "1em",
                                                //      marginRight: "1em",
                                                //      marginTop: "1em",
                                                //      marginBottom: "1em",

                                                // }}
                                                >
                                                    <Col span={1}></Col>
                                                    <Col span={22} style={{ marginTop: "1em", alignItems: "center" }} >
                                                        {/* <Form.Item required tooltip="This is a required field"> */}
                                                        <Image width={150} src="https://entertain.teenee.com/kpop/img6/1300053.jpg" />
                                                        {/* </Form.Item> */}

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>
                                                <Row style={{ marginTop: "1em" }}>
                                                    <Col span={24}>

                                                        <Row>

                                                            <Col span={7} style={{ textAlign: "right" }}>
                                                                <span >รหัส :</span>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col span={14}>

                                                                <span >{partnerUId}</span>
                                                            </Col>
                                                            <Col span={2}></Col>
                                                        </Row>
                                                        <Row style={{ marginTop: "1em", marginBottom: "1em" }}>

                                                            <Col span={7} style={{ textAlign: "right" }}>
                                                                <span>สถานะ :</span>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col span={14} >
                                                            <Switch onChange={switchOnChange} checked={isUseflg} />
                                                            </Col>
                                                            <Col span={2}></Col>
                                                        </Row>

                                                    </Col>

                                                </Row>
                                                {/* <Form.Item label="Switch" valuePropName="checked">
                                                        <Typography >รหัส 9298430284 </Typography>
                                                    </Form.Item>
                                                    <Form.Item label="Switch" valuePropName="checked">
                                                        <Switch />
                                                    </Form.Item> */}
                                            </div>
                                        </Col>
                                    </Row>

                                </Form>
  
        </>
    )
}

export default PartnerDataInfo
