import { Col, Row, Space, Input, Select, Button, Form, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Partner } from 'src/domain/partnerType';
import { Truck } from 'src/domain/truckDataType';
import { TruckType } from 'src/domain/truckType';
import classes from "../mastercss/jobrenger.module.css";
import jobRengerMoveService from 'src/services/JobRengerMoveService';
import { showMessage } from 'src/utils';
import { useAppSelector } from 'src/store';


interface IProps {
  onOK: (newTruck: any ) => void ;
  // truckRegist : string
  // sessionUser ?: SessionUser;
}

const AddTruckPopup : React.FC<IProps> = (props) => {

  const [truckTypes, setTruckTypes] = useState<TruckType[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [truck,setTruck] = useState<Truck>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const id = 0;
  const truckId = 0;
 
  const { sessionUser } = useAppSelector((state) => state.auth)
  const userId = sessionUser.userId;
  const username = sessionUser.username
  const station = sessionUser.station;

  
  const handleSave =(value :any) =>{
     console.log("save value", value)
     props.onOK(value);
  }

  

  useEffect(() => {

    jobRengerMoveService.getAllTruckType()
        .then((res) => {
          setTruckTypes(res.data)
        })

        jobRengerMoveService.getAllPartner()
        .then((res) => {
          setPartners(res.data)
        })   
}, []);


  const onFinish = async (values: any) => {

  console.log(truckId);

  const _truck: Truck = {

      id: truckId,
      registId: values.registId,
      truckTypeId: values.truckTypeId,
      partnerUId: values.partnerUId ,
      // driverUId: values.driverUId,
       status: "01",
      // truckUId: truck.truckUId,

      userIdLogin: userId,
      usernameLogin: username,
      savStation: station,

  };

  console.log(_truck);

  if (id === 0) {
      try {
        const res = await jobRengerMoveService.addTruck(_truck);
        setTimeout(() => {
          console.log("onfinist ",res.data);
          if(res.data.status === true){
            message.success("save success", 1.5);
            const truck:Truck = {..._truck,truckUId:res.data.truckUId}
            handleSave(truck);
          }else{
            message.error("บันทึกไม่สำเร็จ ", 1.5);
            setLoading(false);
          }
        }, 1000);  
        
        
          // isSave(true);
          // history.replace("/app/truck/list");
          // message.success("New truck Complete!", 1.5);
          // setTrigger(false);
      } catch (err) {
          showMessage("Something went wrong!");
          setLoading(false);
          // setTrigger(false);
      }
  } 
  // else if (id !== 0) {
  //     try {
  //         const res = await truckService.updateTruck(_truck, partnerUId, truckId);
  //         history.replace("/app/truck/view/" + id);
  //         message.success("Update truck Complete!", 1.5);
  //     } catch (err) {
  //         showMessage("Something went wrong!");
  //     }
  // }
};

const onFinishFailed = (errorInfo: any) => {
  setLoading(false);
};
  

  return (
    <>
      <Form
      form = {form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="ทะเบียนรถ"
          name="registId"
          rules={[{ required: true, message: 'กรอกทะเบียนรถ' ,  },{max : 20,message:'ความยาวไม่เกิน 20 อักษร'}]}
          required tooltip="This is a required field">

          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="ชนิดรถ"
          name="truckTypeId"
          rules={[{ required: true, message: 'กรอกชนิดรถ' }]}
          required tooltip="This is a required field">

          <Select>
            
            {truckTypes.map((item)=>
              <Select.Option
               value ={item.id}
               key={item.id}
              >
                {item.typeName}

              </Select.Option>
            )}
            

            {/* {trucks.map((item) =>
                <Option
                  value={item.registId}
                  key={item.truckUId}
                  // label={item.registId}
                  >

                  {item.registId}

                </Option>
              )} */}

            </Select>
        </Form.Item>
        <Form.Item
          label="partner"
          name="partnerUId"
          rules={[{ required: true, message: 'กรอกPartner' }]}
          required tooltip="This is a required field">

          <Select>
          {partners.map((item)=>
              <Select.Option
               value ={item.partnerUId}
               key={item.id}
              >
                {item.name}

              </Select.Option>
            )}
            </Select>
        </Form.Item>
        </Form>
        <div style={{textAlign:'center'}}>
        <Button loading={loading} onClick={()=> {setLoading(true); form.submit()}} >บันทึก</Button>
        <Button>ยกเลิก</Button>
        </div>

        {/* <Space direction="vertical" size="middle" style={{ display: 'flex', paddingTop: '10px' }}>
          <Row>
            <Col span={12} className={classes.labelright}>
              ทะเบียนรถ
            </Col>
            <Col span={12}>
              <Input className={classes.inputtext}></Input>
            </Col>
          </Row>
          <Row>
            <Col span={12} className={classes.labelright}>
              ชนิดรถ
            </Col>
            <Col span={12}>
              <Select placeholder="" className={classes.select}>
                <Option value="a">a</Option>
                <Option value="b">b</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={12} className={classes.labelright}>
              partner
            </Col>
            <Col span={12}>
              <Select placeholder="" className={classes.select}>
                <Option value="a">a</Option>
                <Option value="b">b</Option>
              </Select>
            </Col>
          </Row>
          <Row >
            <Col span={24} style={{ textAlign: "center" }}>
              <Space direction="horizontal" >
                <Button>บันทึก</Button>
                <Button >ยกเลิก</Button>
              </Space>
            </Col>
          </Row>
        </Space> */}
      
    </>
  )
}

export default AddTruckPopup