import React, { useState, useEffect } from "react";
import { Form, Input, Typography, Row, Col, message } from "antd";

import { useHistory } from "react-router-dom";
import { Officer } from "src/domain/officerType";

import officerService from "src/services/officerService";
import { showMessage } from "src/utils";

interface IProps {
  id: number;
  trigger: any;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const FormOfficer: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  const [officer, setOfficer] = useState<Officer>({} as Officer);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    if (Number(id) !== 0) {
      let x = officerService.getOfficerById(Number(id));
      setOfficer(x);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      firstname: officer.firstname,
      lastname: officer.lastname,
      address: officer.address,
      position: officer.position,
      idCard: officer.idCard,
    });
  }, [officer]);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = (values: any) => {
    if (id === 0) {
      let getId = Date.now();
      let x = {
        id: getId,
        firstname: values.firstname,
        lastname: values.lastname,
        address: values.address,
        position: values.position,
        idCard: values.idCard,
      };
      officerService.newOfficer(x);
      history.goBack();
      message.success("New officer successfully");
    } else {
      let y = {
        id: Number(id),
        firstname: values.firstname,
        lastname: values.lastname,
        address: values.address,
        position: values.position,
        idCard: values.idCard,
      };
      try {
        officerService.updateOfficer(y);
        history.push("/app/officers/list");
        // console.log(res);
        // history.push({
        //   pathname: "/app/officers/list",
        //   // search: "?update=true",
        //   state: res,
        // });
        message.success("Update successfully");
        setTrigger(false);
      } catch (err) {
        showMessage(JSON.stringify(err));
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row style={{ marginBottom: "3em" }}>
          <Col span={24} style={{ marginTop: "2em" }}>
            <Form.Item
              wrapperCol={{ offset: 6, span: 12 }}
              // rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Typography.Title level={3}>Officer Information</Typography.Title>
            </Form.Item>
            <Form.Item
              label="ID card"
              name="idCard"
              rules={[
                { required: true, message: "Please input your ID card!" },
                {
                  min: 13,
                  max: 13,
                  message: "Please input your Id card 13 character",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Firstname"
              name="firstname"
              rules={[
                { required: true, message: "Please input your firstname!" },
                {
                  min: 3,
                  max: 50,
                  message:
                    "Please input your firstname between 3 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Lastname"
              name="lastname"
              rules={[
                { required: true, message: "Please input your lastname!" },
                {
                  min: 3,
                  max: 50,
                  message:
                    "Please input your lastname between 3 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Address"
              name="address"
              rules={[
                { required: true, message: "Please input your address!" },
                {
                  min: 5,
                  max: 50,
                  message:
                    "Please input your addresss between 5 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Position"
              name="position"
              rules={[
                { required: true, message: "Please input your position!" },
                {
                  min: 3,
                  max: 50,
                  message:
                    "Please input your position between 3 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormOfficer;
