import { Button, Col, DatePicker, Form, message, Radio, RadioChangeEvent, Row, Space } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import React, { useState } from 'react'
import { FilterItem } from './filterItem'
import type { DatePickerProps } from 'antd';
import moment from 'moment'

interface IProps {
  onOK:(filterItem:FilterItem)=>void
}

const SearchDatePopup: React.FC<IProps> = (props) => {

  const [form] = Form.useForm();
  const [dateType, setDateType] = useState("");
  const [startDate,setStartDate] =useState<any>();
  const [endDate,setEndDate] =useState<any>();

  const dateFormat = "DD/MM/YYYY";

  const onChangeRadio = (e: RadioChangeEvent) => {
    // console.log('radio checked', e.target.value);
    setDateType(e.target.value);
  };

  const dateTypeString =(dateType : string)=>{
       return dateType==='BOOK_DATE'?'วันที่จอง' : 'วันที่ใช้' 
  }

  // const validateOk = (job : JobRengerMove): boolean =>{
  //   console.log(form.getFieldValue("partnerJobRefNo"))
  //   form.getFieldValue("partnerName")
  //  if(!isNullAllEmpty( form.getFieldValue("partnerJobRefNo"))){
  //     if(isNullAllEmpty( form.getFieldValue("partnerName")) ){
  //        message.error("มีเลขที่อ้างอิงต้องเลือกบริษัท") ;
  //        return false
  //     }
  //  }
  //  return true;
  // }

  const onReset = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    // setLoading(false);
   };
 
  const validateDatePopup = (filterItem : FilterItem):boolean =>{
    let sDate = startDate.format("YYYYMMDD");
    let eDate = endDate.format("YYYYMMDD");
    console.log("sDate ", sDate);
    console.log("eDate ",eDate);

    if(eDate < sDate){
      message.error("วันที่ถึง ต้องมากกว่าหรือเท่ากับ วันที่จาก");
      return false;
    }
    
    return true;
  }


  const handleOnClick =()=>{
    
     const filterItem : FilterItem ={
       name:"date",
       message:'ประเภทวัน :'+ dateTypeString(dateType) +'  ตั้งแต่ - ถึง : '+startDate.format(dateFormat) +" - " + endDate.format(dateFormat),
       payload:{
         dateType:dateType,
         startDate: startDate.format("YYYYMMDD") ,
         endDate:endDate.format("YYYYMMDD") ,
       }

     }

     if(!validateDatePopup(filterItem)) return;

     props.onOK(filterItem);
  }

  const onChangeStartDate: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(moment(date));
    
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
    setEndDate(moment(date));
    
  };

  return (
    <>
      <Form
         // {...formItemLayout}
         // layout={formLayout}
         form={form}
         onFinish={handleOnClick}
         // initialValues={{ layout: formLayout }}
         // onValuesChange={onFormLayoutChange}
      >
       <Form.Item
        // label="Username"
        name="dateType"
        rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]}
      >
         <Radio.Group 
            onChange={onChangeRadio} value={dateType} >
            <Space direction="vertical">
              <Radio value={"BOOK_DATE"}>วันที่จอง</Radio>
              <Radio value={"USE_DATE"}>วันที่ใช้งาน</Radio>
              
            </Space>
          </Radio.Group>
      </Form.Item>
      วันที่จาก
      <Form.Item
        // label="Username"
        name="startDate"
        rules={[{ required: true, message: 'กรุณาเลือกวันที่เริ่ม' }]}
      >
         <DatePicker onChange={onChangeStartDate} format={dateFormat} />
      </Form.Item>

      ถึง
      <Form.Item
        // label="Username"
        name="endDate"
        rules={[{ required: true, message: 'กรุณาเลือกวันที่ถึง' }]}
      >
         <DatePicker  onChange={onChangeEndDate} format={dateFormat}></DatePicker>
      </Form.Item>

      </Form>
      <Space direction="vertical" >
     
      <Row>
        <Col>
        <Space>
          <Button onClick={onReset}>ล้าง</Button>
          <Button type="primary" danger onClick={() => {  form.submit() }} >ok</Button>
          </Space>
        </Col>
      </Row>
      </Space>
    </>
  )
}
export default SearchDatePopup
