import { Col, Row, Space,Input,Select, Button,Form, message } from 'antd'
import { Option } from 'antd/lib/mentions';
import React, { useState } from 'react'
import { Driver } from 'src/domain/driverType';
import JobRengerMoveService from 'src/services/JobRengerMoveService';
import { useAppSelector } from 'src/store';
import { showMessage } from 'src/utils';
import classes from "../mastercss/jobrenger.module.css";

interface IProps {
  onOK: (newTruck: any ) => void ;
  // driverType : string;
  // sessionUser ?: SessionUser;
}


const AddDriverPopup  : React.FC<IProps> = (props) => {

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const id = 0;
  const driverId = 0;


  const { sessionUser } = useAppSelector((state) => state.auth)
  const userId = sessionUser.userId;
  const username = sessionUser.username
  const station = sessionUser.station;

  const handleSave =(value :any) =>{
    console.log("save value", value)
    props.onOK(value);
 }

  const onFinish = async (values: any) => {
    
    // console.log(driverId);

    const _driver: Driver = {
  
        id: driverId,
        firstname: values.firstname,
        lastname: values.lastname,
        identityId: values.identityId ,
        drvLicenseId:values.drvLicenseId,
        tel:values.tel,
        fullname:values.firstname +"  "+values.lastname,
        useFlg : 0,
        // partnerUId : 

        userIdLogin: userId,
        usernameLogin: username,
        savStation: (station!== undefined)?station:"",
  
    };
  
    console.log(_driver);
  
    if (id === 0) {
        try {
          const res = await JobRengerMoveService.addDriver(_driver);
          setTimeout(() => {
            console.log("onfinist ",res.data);
            if(res.data === true){
              message.success("save success", 1.5);
              const driver:Driver = {..._driver,driverUId:res.data.driverUId}
              handleSave(driver);
            }else{
              message.error("บันทึกไม่สำเร็จ ", 1.5);
              setLoading(false);
            }
          }, 1000);  
        
        } catch (err) {
            showMessage("Something went wrong!");
            setLoading(false);
            // setTrigger(false);
        }
    } 
    // else if (id !== 0) {
    //     try {
    //         const res = await truckService.updateTruck(_truck, partnerUId, truckId);
    //         history.replace("/app/truck/view/" + id);
    //         message.success("Update truck Complete!", 1.5);
    //     } catch (err) {
    //         showMessage("Something went wrong!");
    //     }
    // }
  };
  
  const onFinishFailed = (errorInfo: any) => {
    setLoading(false);
  };

  return (
    <>
 <Form
      form = {form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      >
      <Form.Item
          label="ชื่อพนักงานขับรถ"
          name="firstname"
          rules={[{ required: true, message: 'กรอกชื่อพนักงาน' ,  },{max : 255,message:'ความยาวไม่เกิน 255 อักษร'}]}
          required tooltip="This is a required field">

          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="นามสกลุล"
          name="lastname"
          rules={[{ required: true, message: 'กรอกนามสกุล' ,  },{max : 255,message:'ความยาวไม่เกิน 255 อักษร'}]}
          required tooltip="This is a required field">

          <Input placeholder="" />
        </Form.Item> 
        <Form.Item
          label="เบอร์โทร"
          name="tel"
          rules={[{ required: true, message: 'กรอกเบอร์โทร' ,  },{max : 10,message:'ความยาวไม่เกิน 10 อักษร'}]}
          required tooltip="This is a required field">

          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="เลขที่บัตรประชาชน"
          name="identityId"
          rules={[{ required: true, message: 'กรอกเลขบัตรประชาชน' ,  },{max : 13,message:'ความยาวไม่เกิน 13 อักษร'}]}
          required tooltip="This is a required field">

          <Input placeholder="" />
        </Form.Item> 

        <Form.Item
          label="เลขที่ใบขับขี่"
          name="drvLicenseId"
          rules={[{max : 13,message:'ความยาวไม่เกิน 13 อักษร'}]}
          required tooltip="This is a required field">

          <Input placeholder="" />
        </Form.Item> 
        <div style={{textAlign:'center'}}>
          <Button loading={loading} onClick={()=> {setLoading(true); form.submit()}} >บันทึก</Button>
          <Button>ยกเลิก</Button>
        </div>

      </Form>
    </>
  )
}

export default AddDriverPopup