import { Col, Row, Table } from 'antd'
import classes from "../mastercss/master.module.css";
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import React from 'react'

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    // isSave?: any;
}

const columnUserHitorys = [
    {
        title: 'ลำดับ',
        dataIndex: 'id',
    },
    {
        title: 'วันที่ใช้งาน',
        dataIndex: 'shortName',
    },
    {
        title: 'เลขที่งาน',
        dataIndex: 'tel',
    },
    {
        title: 'ชนิดรถ',
        dataIndex: 'taxNo',
    },
    {
        title: 'ทะเบียนรถ',
        dataIndex: 'taxNo',
    },
    {
        title: 'พนักงานขับรถ',
        dataIndex: 'taxNo',
    },
];


const TruckHistoryInfo: React.FC<IProps> = (props) => {
    return (
        <>
            <Row>
                <Col span={24}>
                    {/* <div className={classes.divAdd2}
                        style={{ width: "98%", height: "500px" }}
                    > */}
                    <div className={classes.divListDetail} >
                        <Row >
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Table
                                    columns={columnUserHitorys}
                                // rowKey={(item: Business) => item.id}
                                // dataSource={businessList}

                                >

                                </Table>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default TruckHistoryInfo
