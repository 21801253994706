import { Button, Col, Input, Row, Tabs, Form, Radio, Image, Switch, Typography, Divider, message, Select, Table, Upload } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import Avatar from 'antd/lib/avatar/avatar';
import classes from "../owner/master.module.css";
import { RouteComponentProps, useHistory } from 'react-router';
import { Truck } from 'src/domain/truckDataType';
import truckService from "src/services/truckService";
import { showMessage } from "src/utils";
import { Option } from 'antd/lib/mentions';
import { TruckType } from 'src/domain/truckSelectType';
import { Partner } from 'src/domain/partnerType';
//import { Driver } from 'src/domain/driverType';
import Modal from 'antd/lib/modal/Modal';
import TruckDataInfo from './TruckDataInfo';
import TruckDataDetailInfo from './TruckDataDetailInfo';
import TruckTaxInfo from './TruckTaxInfo';
import TruckActInfo from './TruckActInfo';
import TruckInsuranceInfo from './TruckInsuranceInfo';
import TruckHistoryInfo from './TruckHistoryInfo';

// import InfiniteScroll from 'react-infinite-scroller';

const { TabPane } = Tabs;
const { TextArea } = Input;

// interface IProps {
//     id: number;
//     trigger: boolean;
//     setTrigger: any;
// }

interface IParams {
    id: string;
    userId: string;
    username: string;
    station: string;
}




function callback(key: any) {
    console.log(key);
}

const { Search } = Input;

const TruckForm: React.FC<RouteComponentProps<IParams>> = (props) => {

    const id = Number(props.match.params.id);
    const userId = Number(99);
    const username = "sittiponn";
    const station = '11';


    // const { id, trigger, setTrigger } = props;
    const history = useHistory();
    const [truck, setTruck] = useState<Truck>({} as Truck);
    const [form] = Form.useForm();
    const { Option } = Select;
    const truckType: TruckType[] = [];
    const [trucktypes, setTrucktypes] = useState<TruckType[]>([]);

    const partner: Partner[] = [];
    const [partners, setPartners] = useState<Partner[]>([]);
    // const [driver, setDriver] = useState<Driver[]>([]);
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        truckService.getTruckById(Number(id))
            .then((res) => {
                setTruck(res.data)
            })

        truckService.getAllTruckType().then((res) => {
            setTrucktypes(res.data)
        })

        //   truckService.getCapabilitiesByWorkerId(Number(id)).then((res)=> {
        //     console.log("getCapabilitiesByWorkerId",res.data)
        //     const caps:number[] = res.data.map((item:any)=>{

        //        return item.capabilityId ;
        //     });
        //     console.log("item" , caps);
        //     setWorkerCaps(caps);
        //   })

    }, []);


    const onFinish = async (values: any) => {
        console.log("Succes :", values);
        const _truck: Truck = {
            // id: id,
            // registId: values.registId,
            // driverName: values.driverName,
            // truckTypeName: values.truckTypeName,
            // useFlgStr: values.useFlgStr,
            //  imgDriver :

            id : id,
            registId: values.registId,
            truckTypeId: values.truckTypeId,
            partnerUId : values.partnerUId,
            driverUId : values.driverUId,
            status : values.status,
        };

        if (id === 0) {
            try {
                const res = await truckService.addTruck(_truck);
                console.log(res);
                history.replace("/app/truck/list");
                message.success("New truck Complete!", 1.5);
                // setTrigger(false);
            } catch (err) {
                showMessage("Something went wrong!");
                // setTrigger(false);
            }
        } else if (id !== 0) {
            try {
                const res = await truckService.updateTruck(_truck);
                history.replace("/app/truck/view/" + id);
                message.success("Update truck Complete!", 1.5);
                // setTrigger(false);
            } catch (err) {
                showMessage("Something went wrong!");
                // setTrigger(false);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
        // setTrigger(false);
    };

    function handleChangeTruckType(values: number) {
        const _truckTypeId = values;
        // trucktypes.forEach(truckType => {
        //     if (values.includes(truckType.id)) {
        //         _truckTypes.push(truckType);
        //     }
        // })

        // setTrucktypes(_truckTypes);

        console.log("truckType >>> ", values, _truckTypeId)

        truckService.getAllPartner().then((res) => {
            setPartners(res.data)
        })

        // truckService.getAllPartner(Number(id)).then((res)=> {
        //     console.log("getCapabilitiesByWorkerId",res.data)
        //     const caps:number[] = res.data.map((item:any)=>{

        //        return item.capabilityId ;
        //     });
        //     console.log("item" , caps);
        //     setWorkerCaps(caps);
        //   })

    }

    function handleChangePartner(values: number[]) {
        const _partners: Partner[] = [];
        // partners.forEach(partner => {
        //     if (values.includes(partner.id)) {
        //         _partners.push(partner);
        //     }
        // })

        // setPartners(_partners);

        // console.log("partner >>> ", values, _partners)

        // truckService.getDriver(Number(), "").then((res) => {
        //     setDriver(res.data)
        // })

    }




    const handleModelOk = () => {
        // this.setState({ loading: true });
        // setTimeout(() => {
        //     this.setState({ loading: false, visible: false });
        // }, 3000);
    };

    const handleModelCancel = () => {
        // this.setState({ visible: false });
    };


    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);

    // const onChange = ({ fileList: newFileList }) => {
    //   setFileList(newFileList);
    // };

    const onPreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        //   const image = new Image();
        //   image.src = src;
        //   const imgWindow = window.open(src);
        //   imgWindow.document.write(image.outerHTML);
    };

    return (

        <>
            <div
            // className={classes.divAdd}
            >

                <div style={{
                    padding: "20px",
                    width: "100%",
                    marginTop: "4em", 
                }}>

                    <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="ข้อมูล" key="1">
                                <TruckDataInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                          
                        </TabPane>

                        <TabPane tab="รายละเอียดรถ" key="2">
                            <TruckDataDetailInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                            

                        </TabPane>
                        <TabPane tab="ภาษี" key="3">
                        <TruckTaxInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />


                        </TabPane>
                        <TabPane tab="พรบ" key="4">
                        <TruckActInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />

                        </TabPane>
                        <TabPane tab="ประกัน" key="5">
                        <TruckInsuranceInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                        </TabPane>
                        <TabPane tab="ข้อมูลการวิ่งรถ" key="6">
                        <TruckHistoryInfo
                                    id={id}
                                    userId={userId}
                                    username={username}
                                    station={station}
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                        </TabPane>
                        <TabPane tab="รูปรถ" key="7">

                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                // fileList={fileList}
                                // onChange={onChange}
                                onPreview={onPreview}
                            >
                                {fileList.length < 5 && '+ Upload'}
                            </Upload>

                        </TabPane>

                    </Tabs>
                </div>


            </div>

        </>
    )
}

export default TruckForm
