import React, { useState, useEffect } from "react";
import PageHeader from "src/layouts/PageHeader";
import { Button, Divider, Table, Tag } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Officer } from "src/domain/officerType";

import { PlusOutlined } from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";
import officerService from "../../services/officerService";

const OfficerList: React.FC = (props: any) => {
  // console.log(props);
  const [datas, setDatas] = useState<Officer[]>([]);
  const history = useHistory();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    let x: any = officerService.getOfficer();
    console.log(x);
    setDatas(x);
  };

  const handleDelete = (id: any) => {
    let _id = Number(id);
    officerService.deleteOfficer(_id);
    init();
  };

  const columns = [
    {
      title: "ID Card",
      dataIndex: "idCard",
      key: "idCard",
      width: 50,
    },
    {
      title: "FirstName",
      dataIndex: "firstname",
      key: "firstname",
      render: (value: string, item: Officer, index: number) => (
        <Link to={`/app/officers/view/${item.id}`}>{value}</Link>
      ),
    },
    {
      title: "Lastname",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: 150,
    //   render: (value: string, item: Officer, index: number) => (
    //     // <span style={{ color: "red", cursor: "pointer" }}>
    //     <>
    //       {/* <span style={{ cursor: "pointer" }}>
    //         <Link
    //           to={`/app/officers/edit/${item.id}`}
    //           style={{ color: "orange" }}
    //         >
    //           Update
    //         </Link>
    //       </span>
    //       <Divider type="vertical" /> */}
    //       <span
    //         style={{ cursor: "pointer", color: "red" }}
    //         onClick={() => handleDelete(item.id)}
    //       >
    //         Delete
    //       </span>
    //     </>
    //     // </span>
    //   ),
    // },
  ];

  return (
    <>
      <PageHeader title="Officer List">
        <Button
          size="small"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/officers/new")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div>
          <Table
            columns={columns}
            dataSource={datas}
            size="small"
            rowKey={(item: any) => item.id}
          />
        </div>
      </Content>
    </>
  );
};

export default OfficerList;
