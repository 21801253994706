import { Button, PageHeader, Space } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { RouteComponentProps, useHistory } from 'react-router';
import SubjectForm from "./SubjectForm";
import { dummydata } from './data';
import { Subject } from 'src/domain/subjectType';
import TextArea from 'antd/lib/input/TextArea';
import subjectService from 'src/services/subjectService';


interface IParams {
    id: string;
  }

const EditSubject:React.FC<RouteComponentProps<IParams>> = (props) => {

    const [trigger, setTrigger] = useState<boolean>(false);
    
    const [subject, setSubject] = useState<Subject>({} as Subject);

   
    const history = useHistory();
    const id = Number(props.match.params.id);
    // console.log(props.match.params.id)
    // let subjectId:number = Number(props.match.params.id)
    //   let data =  dummydata.filter((item:any) => item.id === subjectId );
    //   // console.log("data"+data.name)
 
 useEffect(() => {
  
  let data =  subjectService.getSubjectById(id)
   setSubject(data)
 }, []);

    return (
        <>
        <PageHeader
          title={`Edit  : ${subject.name}`}
          // onClose={() => history.push("/app/users/list")}
        >
          <Space>
            <Button
              danger
              size="small"
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => setTrigger(true)}
            >
              Save
            </Button>
            <Button
              size="small"
              // type="primary"
              icon={<CloseCircleOutlined />}
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Space>
        </PageHeader>
        <Content>
        <SubjectForm id={id} trigger={trigger} setTrigger={setTrigger} />
        </Content>
      </>
    )
}

export default EditSubject
