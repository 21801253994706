import React, { useState } from "react";
import { Link } from "react-router-dom";
/* antd */
import { Layout, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

interface NavItem {
  id: number;
  icon: any;
  name: string;
  path: string;
  subItems: NavItem[];
}

const initNavItems: NavItem[] = [
  {
    id: 1,
    icon: <DesktopOutlined />,
    name: "Dashboard",
    path: "/app",
    subItems: [],
  },
  {
    id: 2,
    icon: <FileOutlined />,
    name: "business",
    path: "/app/business/list",
    subItems: [],
  },
  {
    id: 3,
    icon: <FileOutlined />,
    name: "partner",
    path: "/app/partner/list",
    subItems: [],
  },
  {
    id: 4,
    icon: <FileOutlined />,
    name: "การจองรถ",
    path: "",
    subItems: [
      {
        id: 1101,
        icon: <FileOutlined />,
        name: "จับคู่รถ",
        path: "/app/matching/list",
        subItems: [],
      } ,
      {
        id: 1102,
        icon: <FileOutlined />,
        name: "จัดการการจองรถ",
        path: "/app/subject/list",
        subItems: [],
      } ,
      {
        id: 1103,
        icon: <FileOutlined />,
        name: "ติดตามรถ",
        path: "/app/subject/list",
        subItems: [],
      } ,
    ],
  } ,
  {
    id: 5,
    icon: <FileOutlined />,
    name: "ส่งงานให้ Partner",
    path: "/app/send2partner/list",
    subItems: [],
  } ,

  {
    id: 6,
    icon: <FileOutlined />,
    name: "job_renger",
    path: "/app/jobrenger/list",
    subItems: [],
  },
  {
    id: 7,
    icon: <FileOutlined />,
    name: "job_renger_other",
    path: "/app/jobrenger/other",
    subItems: [],
  },
  // {
  //   id: 6,
  //   icon: <FileOutlined />,
  //   name: "mail",
  //   path: "/app/test/mail",
  //   subItems: [],
  // } ,
  // {
  //   id: 7,
  //   icon: <FileOutlined />,
  //   name: "pic",
  //   path: "/app/partner/partnerAvatar",
  //   subItems: [],
  // } ,

  // {
  //   id: 6,
  //   icon: <FileOutlined />,
  //   name: "truck",
  //   path: "/app/truck/list",
  //   subItems: [],
  // },

  // {
  //   id: 7,
  //   icon: <FileOutlined />,
  //   name: "driver",
  //   path: "/app/driver/list",
  //   subItems: [],
  // },

 
  // {
  //   id: 8,
  //   icon: <DesktopOutlined />,
  //   name: "Products",
  //   path: "/app/products",
  //   subItems: [],
  // },
  // {
  //   id: 9,
  //   icon: <DesktopOutlined />,
  //   name: "Users",
  //   path: "/app/users",
  //   subItems: [],
  // },
  // {
  //   id: 10,
  //   icon: <DesktopOutlined />,
  //   name: "Posts",
  //   path: "/app/posts",
  //   subItems: [],
  // },
  // {
  //   id: 11,
  //   icon: <DesktopOutlined />,
  //   name: "Counter",
  //   path: "/app/counter",
  //   subItems: [],
  // },
  // {
  //   id: 12,
  //   icon: <DesktopOutlined />,
  //   name: "Officer",
  //   path: "/app/officers",
  //   subItems: [],
  // },
  // {
  //   id: 13,
  //   icon: <DesktopOutlined />,
  //   name: "Teacher",
  //   path: "/app/teacher",
  //   subItems: [],
  // },
  // {
  //   id: 14,
  //   icon: <DesktopOutlined />,
  //   name: "Student",
  //   path: "/app/Student",
  //   subItems: [],
  // },
  // {
  //   id: 15,
  //   icon: <FileOutlined />,
  //   name: "Subject",
  //   path: "/app/subject/list",
  //   subItems: [],

  // } ,
  // {
  //   id: 16,
  //   icon: <FileOutlined />,
  //   name: "Partner",
  //   path: "",
  //   subItems: [
  //     {
  //       id: 1001,
  //       icon: <FileOutlined />,
  //       name: "กำหนดข้อมูลเจ้าของรถ",
  //       path: "/app/subject/list",
  //       subItems: [],
  //     } ,
  //     {
  //       id: 1002,
  //       icon: <FileOutlined />,
  //       name: "กำหนดข้อมูลรถ",
  //       path: "/app/subject/list",
  //       subItems: [],
  //     } ,
  //     {
  //       id: 1003,
  //       icon: <FileOutlined />,
  //       name: "กำหนดข้อมูลพนักงานขับรถ",
  //       path: "/app/subject/list",
  //       subItems: [],
  //     } ,
  //   ],
  // } ,


];

const SiderNav = () => {
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [navItems, setNavItems] = useState(initNavItems);

  return (
    <Sider
      className="side-nav"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed((prev) => !prev)}
    >
      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        defaultOpenKeys={["sub5"]}
      >
        {navItems.map((navItem) =>
          navItem.subItems.length === 0 ? (
            <Menu.Item key={navItem.id} icon={navItem.icon}>
              <Link to={navItem.path}>{navItem.name}</Link>
            </Menu.Item>
          ) : (
            <SubMenu
              key={`sub+${navItem.id}`}
              icon={navItem.icon}
              title={navItem.name}
            >
              {navItem.subItems.map((subItem) => (
                <Menu.Item key={subItem.id}>
                  <Link to={subItem.path}>{subItem.name}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          )
        )}
      </Menu>
      {/* <Menu defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item
          key="1"
          icon={<PieChartOutlined />}
          style={{ marginBottom: "20px" }}
        >
          <Link to="/">Dashboard</Link>
        </Menu.Item>

        <Menu.Item key="2" icon={<DesktopOutlined />}>
          <Link to="/Products">Products</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<DesktopOutlined />}>
          <Link to="/Customers">Customers</Link>
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<DesktopOutlined />}
          style={{ marginBottom: "20px" }}
        >
          <Link to="/Orders">Orders</Link>
        </Menu.Item>

        <SubMenu key="sub1" icon={<UserOutlined />} title="User">
          <Menu.Item key="5">Tom</Menu.Item>
          <Menu.Item key="6">Bill</Menu.Item>
          <Menu.Item key="7">Alex</Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
          <Menu.Item key="8">Team 1</Menu.Item>
          <Menu.Item key="9">Team 2</Menu.Item>
        </SubMenu>
        <Menu.Item key="10" icon={<FileOutlined />}>
          Files
        </Menu.Item>
      </Menu> */}
    </Sider>
  );
};

export default SiderNav;
