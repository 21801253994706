import * as WebConfig from "../constant/WebConfig";
// export  const  baseUrl = `${WebConfig.udomitHostUrl}`;
// export  const  baseUrl ='https://app15.nimsoft.xyz/api/'

export type urlType ="SECURITY"|"FLEET"
export const rootUrl = (url:urlType) =>{
    if(url === "SECURITY"){
        return WebConfig.securityUrl
    }

    if(url === "FLEET"){
        return WebConfig.fleetUrl
    }
}