import { Button, Modal, Table, Pagination, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from "@ant-design/icons";
import classes from "../mastercss/master.module.css";
import { Business } from 'src/domain/businessType';
import { RouteComponentProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import customerBusinessService from "src/services/customerBusinessService";
import BusinessDataInfo from './BusinessDataInfo';
import { useAppSelector } from "../../store";
import { SessionUser } from 'src/store/authen/types';


interface IParams {
    id: string;
    userId: string;
    username: string;
    station: string;
    // userUId: string;
    // partnerUId ?: any;
    // sessionUser : SessionUser;
}


const RegistBusinessList : React.FC<RouteComponentProps<IParams>> = (props) => {

    const {sessionUser} = useAppSelector((state)=> state.auth)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const history = useHistory();
    const [businessList, setBusinessList] = useState<Business[]>([]);
    const [searchData, setSearchData] = useState<any>("");
    const [page, setPage] = useState(1);


    useEffect(() => {

        customerBusinessService.getCustomerBusiinessByCodition("")
            .then((res) => {
                console.log(res.data);
                setBusinessList(res.data)
            })
        console.log("businessList >>> " + businessList,)
    }, []);

    const onSearch = (value: any) => {
        setSearchData(value);
        customerBusinessService.getCustomerBusiinessByCodition(value)
            .then((res) => {
                setBusinessList(res.data)
            })
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const columns = [

        {

            title: 'ลำดับ',
            dataIndex: 'seq',
            // render: (value: string, item: Business, index: number) => ((page - 1) * 10 + index)+1 ,


        },
        {

            title: 'ชื่อคู่ค้า',
            dataIndex: 'firstName',
            render: (value: string, item: Business, index: number) => <Link to={`/app/business/edit/${item.id}`}>{value}</Link>,

        },
        {
            title: 'เบอร์โทร',
            dataIndex: 'tel',
        },
        {
            title: 'เลขที่ผู้เสียภาษี',
            dataIndex: 'cropId',
        },
        {
            title: 'สถานะ',
            key: 'useFlg',
            dataIndex: 'useFlg',
            render: (useFlg: number) => (
                <>
                    <div>
                        {(() => {
                            if (useFlg === 0) {
                                return (
                                    <Tag color="green">{"ใช้งาน"}</Tag>
                                )
                            } else {
                                return (
                                    <div style={{ background: "red" }}>
                                        <Tag color="red">{"ไม่ใช้งาน"}</Tag>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </>
            ),
        },

    ];


    return (
        <>
            <PageHeader title="ข้อมูลคู่ค้า">

                <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    // onClick={() => history.push("/app/business/new")}
                    onClick={() => setIsModalVisible(true)}
                >
                    เพิ่ม
                </Button>

            </PageHeader>

            <Content className="app-page-content" >

                <div className={classes.divList}

                >
                    <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200, marginLeft: "1em", }} />

                    <div className={classes.divListDetail}

                    >

                        <Table style={{
                            marginTop: "1em",
                        }}

                            rowKey={(item: Business) => item.id}
                            dataSource={businessList}
                            columns={columns}
                            pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
                        // pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '15']}}
                        />

                    </div>
                </div>

            </Content>

            <Modal title="เพิ่มข้อมูล คู่ค้า "
                visible={isModalVisible}
                footer={null}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
            >
                <div>
                    <BusinessDataInfo
                        // id={0}
                        // userId={Number(sessionUser.userUId)}
                        // username={"username"}
                        // station={"11"}
                        sessionUser={sessionUser}
                        isSave={(e:any) => {
                            // console.log("onNextStep");
                            onSearch(searchData);
                            handleCancel();
                            console.log("ssssssssssssssssssssss >>>",e)
                        }}
                    >
                    </BusinessDataInfo>
                </div>
            </Modal>
        </>
    )
}

export default RegistBusinessList
