import React, { ReactNode } from "react";
import classes from "./SideNavModule.module.css";
import { Camera, Command, BarChart, Layers, Copy } from "react-feather";

interface IProps {
  showMenu: () => void;
  menuModules: { id: number; name: string; icon: string }[];
}

const iconRecords: Record<string, any> = {
  BlockOutlined: BarChart,
  BookOutlined: Command,
  TableOutlined: Layers,
  TeamOutlined: Copy,
};

const SideNavModule: React.FC<IProps> = (props) => {
  const onMenuClick = () => {
    props.showMenu();
  };

  const getIcon = (iconName: string): any => {
    console.log(iconName);
    const Component = iconRecords[iconName];
    console.log(Component);
    return (
      <Component strokeWidth="1" color="#fff" size={20} onClick={onMenuClick} />

      // <TableOutlined />
    );
  };

  return (
    <div className={classes.sideNavModule}>
      <div>Logo</div>
      {props.menuModules.map((item) => (
        <div className={classes.sideNavModuleIcon}>{getIcon(item.icon)}</div>
      ))}
    </div>
  );
};

export default SideNavModule;
