import http from "../lib/http";
import { rootUrl } from "./baseUrl";
// const url = "http://localhost:8080/partneruser/";
const url = rootUrl("FLEET")+"partneruser/"

const getPartnerByPartnerUId = (partnerUId: string) => {
  console.log("getPartnerByPartnerUId " + url+"byPartnerUId/"+partnerUId);
  return http.get(url+"byPartnerUId/"+partnerUId);
};

const getSecUserByPartnerUId = (partnerUId: string) => {
  console.log("getUserByPartnerUIdSuperadmin " + url+partnerUId+"/superadmin");
  return http.get(url+partnerUId+"/superadmin");
};

const addSecUser = (partnerUId:string,SecUse: any) => {
  console.log("addSecUser >>> ", SecUse)
  return http.post(url+partnerUId+"/addSecUser", SecUse);
};

const updateUser = (partnerUId:string,SecUse: any) => {
  console.log("updateUser >>> ", SecUse)
  return http.post(url+partnerUId+"/updateSecUser", SecUse);
};

// const updateUser = (Partner: any) => {
//   console.log("updateUser >>> ", Partner)
//   return http.post(url+"updateUser", Partner);
// };


const checkSecUserByUsername = (username: string) => {
  console.log(url+"checkSecUser/"+username);
  return http.get(url+"checkSecUser/"+username);
};

const getTruckListToPartner = (partnerUId: string) => {
  console.log(url+partnerUId+"/truckList");
  return http.get(url+partnerUId+"/truckList");
};

const getRangerJobListToPartner = (partnerUId: string) => {
  console.log(url+partnerUId+"/rangerJobList");
  return http.get(url+partnerUId+"/rangerJobList");
};


////////////////////////////////////
const getAllPartner = () => {
    return http.get(url);
  };
  
  
  const getPartnerById = (id: number) => {
    return http.get(url+"byId/"+id);
  };
  
  const getPartnerByCondition = (data:string) => {
    if(data === null || data === ''){
      data = 'null'
    }
    return http.get(url + "byCondition/" + data);
  };
  

  const addPartner = (Partner: any) => {
    console.log("addPartner >>> ", Partner)
    return http.post(url+"addPartner", Partner);
  };

  const updatePartner = (Partner: any) => {
    console.log("updatePartner",Partner)
    return http.post(url + "updatePartner", Partner);
  };
  
///////////////prename////////////////

const getAllPrename = () => {  
    return http.get(url+"prenmeaAll");
};


const getPreanameById = (id: number) => {
  console.log("getTruckById" + id)
  return http.get(url+"prenmeaById/"+id);
};


/////////////////////////////////////
const getLocationAll = () => {  
    console.log("locationall");
    return http.get(url+"locationall");
  };

  const getLocationByAddressPath = (searcdata: string) => {  
    // console.log("ssssssssssssssssssssss " + searcdata);
    return http.get(url+"location/addresspath/"+searcdata);
  };

  
  const getLocationByData = (searcdata: string) => {
    console.log("location" + searcdata)
    return http.get(url+"location/"+searcdata);
  };

  const getSecUserByPartnerId = (partnerId: number) => {
    console.log("secUserByPartnerId" + partnerId)
    return http.get(url+"secUserByPartnerId/"+partnerId);
  };

  //////////////////////////

  

  

  const resetPasswordSecUser = (Partner: any) => {
    console.log("resetPasswordSecUser >>> ", Partner)
    return http.post(url+"resetPasswordSecUser", Partner);
  };


export default {
    getPartnerByPartnerUId,
    getSecUserByPartnerUId,
    checkSecUserByUsername,
    getTruckListToPartner,
    getRangerJobListToPartner,

    getAllPartner,
    getPartnerById,
    getPartnerByCondition,
    addPartner,
    updatePartner,
    getAllPrename,
    getPreanameById,
    getLocationAll,
    getLocationByAddressPath,
    getLocationByData,
    getSecUserByPartnerId,
    addSecUser,
    updateUser
};