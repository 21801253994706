import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Space, Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

import PageHeader from "src/layouts/PageHeader";

import FormOfficer from "./FormOfficer";

const NewOfficer: React.FC = () => {
  const [trigger, setTrigger] = useState<boolean>(false);
  const history = useHistory();
  return (
    <>
      <PageHeader title="New officer">
        <Space>
          <Button
            danger
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => setTrigger(true)}
          >
            Save
          </Button>
          <Button
            size="small"
            // type="primary"
            icon={<CloseCircleOutlined />}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Space>
      </PageHeader>
      <Content>
        <FormOfficer id={0} trigger={trigger} setTrigger={setTrigger} />
      </Content>
    </>
  );
};

export default NewOfficer;
