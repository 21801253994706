import { Button, Col, Input, Row, Tabs, Form, Radio, Image, DatePicker,Switch, Typography, Divider, message, Select, Table, Upload } from 'antd'
import classes from "../mastercss/master.module.css";
import { SaveOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react'
import { ReferenceDetail } from 'src/domain/referenceDetailType';
import moment from 'moment';
import { TruckAct } from 'src/domain/truckActType';
import truckService from 'src/services/truckService';
import { showMessage } from 'src/utils';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    // isSave?: any;
}



const TruckActInfo  : React.FC<IProps> = (props)  => {

    const { id, userId, username, station } = props;
    const { Option } = Select;
    
    const truckId:any = id;
    const [actId, setActId] = useState<any>(0);
    const dateFormat = ['DD/MM/YYYY', 'DD/MM/YY'];

    const [form] = Form.useForm();
    const [truckAct, setTruckAct] = useState<TruckAct>({} as TruckAct);
    const [truckActs, setTruckActs] = useState<TruckAct[]>([]);
    const [insCompany, setInsCompany] = useState<any>();
    const [insCompanies, setInsCompanies] = useState<ReferenceDetail[]>([]);

    useEffect(() => {
        console.log(truckId)

        truckService.getTruckActList(truckId).then((res)=>{
            setTruckActs(res.data)
        }) 
        
        console.log(truckActs)

        truckService.getInsCompanyList().then((res)=>{
            setInsCompanies(res.data)
        })

        console.log(insCompanies)

    }, []);

    const onFinish = async (values: any) => {
        console.log("Succes :", values);
        const _truckAct: TruckAct = {
            id: actId,
            truckId: truckId,
            renewalDate: moment(values.renewalDate).format('YYYYMMDD'),
            insCompany:  values.insCompany,//taxDateDate,
            expirationDate:  moment(values.expirationDate).format('YYYYMMDD'),
            policyNo:   values.policyNo,
            markNo:   values.markNo,
            premiumAmt :  values.premiumAmt,
            ownerName :  values.ownerName,
            remark :  values.remark,
            docNo : values.docNo,
            
            userId: userId,
            username: username,

        };

        console.log("_truckTax >> ",_truckAct)
 
        if (truckActs.length === 0) {
            try {
                const res = await truckService.addTapTruckAct(_truckAct);
                console.log(res);
                // history.replace("/app/truck/list");
                message.success("New truck Complete!", 1.5);

                // setTrigger(false);
            } catch (err) {
                showMessage("Something went wrong!");
                // setTrigger(false);
            }
        } else if (truckActs.length !== 0) {
            try {
                const res = await truckService.updateTapTruckAct(_truckAct);
                // history.replace("/app/truck/view/" + id);
                message.success("Update truck Complete!", 1.5);
            } catch (err) {
                showMessage("Something went wrong!");
                
            }
        }

        onClearTruckTax();

        truckService.getTruckActList(truckId).then((res)=>{
            setTruckActs(res.data)
        })  
    };
    

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const setDateMoment = (value:any) => {
        console.log(value)
      let  year =  value.substring(0,4)
      let  month = value.substring(4,6)
      let  day = value.substring(6,8)
      console.log( year+"/"+ month +"/"+day)
      return day+"/"+ month +"/"+year
    }

     const onSelectTruckAct = async (value: any) => {
     

      let renewalDateMoment = setDateMoment(value.renewalDate)
      let expirationDateMoment = setDateMoment(value.expirationDate)

      console.log("onSelectTruckTax" ,value);
      setTruckAct(value);
    
      setActId(value.id);

      form.setFieldsValue({
         
            id: value.id,
            truckId: value.truckId,
            renewalDate:  moment(renewalDateMoment, dateFormat) ,
            insCompany:  value.insCompany,
            expirationDate:  moment(expirationDateMoment, dateFormat) ,
            policyNo:   value.policyNo,
            markNo:   value.markNo,
            premiumAmt :  value.premiumAmt,
            ownerName :  value.ownerName,
            remark :  value.remark,
            docNo :  value.docNo,
          
      });
      console.log("onSelectTruckActForm",form.getFieldsValue)
    }


    const onClearTruckTax = () => {
      
      form.setFieldsValue({
              
        id: "",
        truckId: "",
        renewalDate: "",
        insCompany:  "",
        expirationDate: "",
        policyNo:   "",
        markNo:   "",
        premiumAmt :  "",
        ownerName :  "",
        remark :  "",
        docNo :"",
          
      });

      console.log(form);
    }


    const columnfilesAct = [
        {
            title: 'ลำดับ',
            dataIndex: 'id',
        },
        {
            title: 'วันที่ต่อายุ',
            dataIndex: 'renewalDate',
        },
        {
            title: 'บริษัทประกัน',
            dataIndex: 'insCompany',
        },
        {
            title: 'เลขที่กรมธรรม์',
            dataIndex: 'policyNo',
        },
        {
            title: 'เลขที่เครื่องหมาย',
            dataIndex: 'markNo',
        },
        {
            title: 'เลขที่เอกสาร',
            dataIndex: 'docNo',
        },
        {
            title: 'วันที่หมดอายุ',
            dataIndex: 'expirationDate',
        },
        {
            title: 'เบี้ยประกัน',
            dataIndex: 'premiumAmt',
        },
        {
            render: ((_:any, row:any) => <EditOutlined  onClick={() => onSelectTruckAct(row)}/>) 
        }
    ];

    return (
        <>
            <Form
                                 form={form}
                                 layout="horizontal"
                                 onFinish={onFinish}
                                 onFinishFailed={onFinishFailed}
                                 labelCol={{ span: 8 }}
                                 wrapperCol={{ span: 16 }}
                            >

                                <Row
                                // style={{ border: "1.5px solid #706d6d" }}
                                >
                                    <Col span={15}>

                                        <div className={classes.divAdd2}>
                                            <Row style={{ marginTop: "2em", }}>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="วันที่ต่ออายุ"
                                                        name="renewalDate"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <DatePicker 
                                                           format={dateFormat}
                                                           />
                                                        {/* <Input placeholder="" /> */}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="บริษัทประกัน"
                                                        name="insCompany"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                    <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            
                                                        >

                                                            {insCompanies.map((item) =>
                                                                <Option value={item.code} key={item.id} label={item.name}>

                                                                    {item.name}

                                                                </Option>
                                                            )}

                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เลขที่กรมธรรม์"
                                                        name="policyNo"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เลขที่เครื่องหมาย"
                                                        name="markNo"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เลขที่เอกสาร"
                                                        name="docNo"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="วันที่หมดอายุ"
                                                        name="expirationDate"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        {/* <Input placeholder="" /> */}
                                                        <DatePicker 
                                                           format={dateFormat}
                                                        //    onChange={getDataTaxDeate} 
                                                           />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เบี้ยประกัน"
                                                        name="premiumAmt"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input 
                                                         type="number"
                                                         pattern="(^[0-9]{0,2}$)|(^[0-9]{0,2}\.[0-9]{0,5}$)"
                                                         step="0.01"
                                                        //  type="number" 
                                                        //  pattern="^(?=.)(\d{1,3}(,\d{3})*)?(\.\d+)?$" placeholder=""
                                                          />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ชื่อผู้เอาประกัน"
                                                        name="ownerName"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="หมายเหตุ"
                                                        name="remark"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row>
                                                <Col span={19}></Col>
                                                <Col span={4}>
                                                    <Button
                                                        size="middle"
                                                        type="primary"
                                                        icon={<SaveOutlined />}
                                                         onClick={() => form.submit()}
                                                    >
                                                        บันทึก
                                                    </Button>
                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={8}>
                                        <div className={classes.divAddImg}>
                                            <Row className={classes.divAddImg2}>
                                                <Col span={1}></Col>
                                                <Col span={22}  >
                                                    <Form.Item required tooltip="This is a required field"
                                                    >
                                                        <Image

                                                            width={250} height={130}
                                                            src="https://www.truckmarketthailand.com/asset/images/truck/10wheel.png" />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                            <Row style={{ marginTop: "1em" }}>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span >รหัส :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14}>

                                                            <span >9298430284</span>
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "1em", marginBottom: "1em" }}>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span>สถานะ :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14} >
                                                            <Switch />
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>


                                

                            </Form>
                            <div>
                            <Row>
                                    <Col span={24} style={{ textAlign: "center" }} className={classes.divAdd2}>
                                        <Table

                                            rowKey={(item: TruckAct) => item.id}
                                            columns={columnfilesAct}
                                            dataSource={truckActs} 
                                        >

                                        </Table>
                                    </Col>
                                </Row>
                            </div>
        </>
    )
}

export default TruckActInfo
