import { Button, Space } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Flex from "src/components/container/Flex";
import {useAppSelector} from"../store"

/* icon */
import { CloseOutlined } from "@ant-design/icons";

interface IProps {
  title: string;
  onClose?: () => void;
  childrend?: React.ReactNode;
}

const PageHeader: React.FC<IProps> = ({ title, onClose, children }) => {
  const history = useHistory();
  const {sessionUser} = useAppSelector((state)=> state.auth)

  console.log("PageHeader");

  const closeHandler = () => {
    if (onClose) onClose();
  };

  return (
    <div
      className="app-page-header"
      // style={{ height: "65px", padding: "0px 20px" }}
    >
      <Flex justifyContent="between" alignItems="center">
        <h3 className="mb-0 mr-3 font-weight-semibold">
          {/* <IntlMessage id={title ? title : "home"} /> */}
          {title ? title : "..."}
        </h3>

        <div className="d-flex flex-row">
          <div className="action-container">{children}</div>
          <div>
            {onClose && (
              <Button
                type="text"
                className="btn-close"
                size="small"
                onClick={closeHandler}
              >
                <CloseOutlined />
              </Button>
            )}
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default PageHeader;
