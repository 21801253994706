import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
} from "antd";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  setSessionUser,
  getSessionUserFromLocal,
} from "src/store/authen/action";
import "./Login.css";

import secUserService from "src/services/secUserService";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function validate(username: string, password: string): string {
  if (!(username === "admin" && password === "adminadmin")) {
    return "Incorrect username or password";
  }

  return "";
}

const LoginForm = () => {
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sessionUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSessionUserFromLocal());

    if (sessionUser.username) history.replace("/app");
  }, []);

  const onFinish = (values: any) => {
    console.log("form", values);
    const { username, password } = values;

    secUserService.login({username,password,appName:"FLEET"}).then((res)=>{
    console.log("login onfinish >>>>",res.data.body);
    if(!res.data.status){
      setError(res.data.message);
      return;
    }

    const sessionUser = res.data.body;
    dispatch(setSessionUser(sessionUser));
    history.replace("/app");

    })

    // const error = validate(username, password);
    // setError(error);

    // if (!error) {
    //   dispatch(setSessionUser({ username: "admin", email: "admin@mail.com" }));
    //   history.replace("/app");
    // }
  };

  const onFinishFailed = (errorInfo:any) => {
    console.log("error x", errorInfo);
  };

  return (
    <>
      {console.log("login in form useEffect", sessionUser.username, "---")}
      {sessionUser.username ? history.replace("/app") : null}
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col span={8}>
          <Card title="Login" className="login-form">
            {error && (
              <Alert
                message="Error"
                description={error}
                type="error"
                closable
                // onClose={onClose}
              />
            )}
            <br />
            <Form
              {...layout}
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LoginForm;
