import React from "react";

import { Avatar, Badge, Dropdown, Menu } from "antd";

import { BellOutlined, SettingOutlined } from "@ant-design/icons";
import { Header } from "antd/lib/layout/layout";
import { useHistory } from "react-router-dom";

import {useAppSelector,useAppDispatch} from"../store"
import { removeSessionUser } from "src/store/authen/action";



const HeaderNav = () => {

  const history = useHistory();
  const {sessionUser} = useAppSelector((state)=> state.auth)
  const dispatch = useAppDispatch();

  console.log("sessionUser",sessionUser);

  const clickLogout =() => {

    console.log("clickLogOut");
    dispatch(removeSessionUser());
    history.replace("/login")
    // localStorage.clear();
   
   // ("sessionUser.username");
  }

  const clickChangePassword =() =>{
    //ทำหน้าจอ เปลี่ยน password
    //secuser >> username password >> 
    history.replace("/app/secuser/changepassword")
  }


  const menu = (
    <Menu>
       <Menu.Item>
        <span onClick={clickChangePassword}>
          change password
        </span>
      </Menu.Item>
      <Menu.Item>
        <span onClick={clickLogout}>
          log out
        </span>
      </Menu.Item>
      
      {/* <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
          3rd menu item
        </a>
      </Menu.Item>  */}
    </Menu>
  );

  
  return (
    
    <Header className="app-header">
      <div
        className="logo d-flex flex-row justify-content-start align-items-center"
        style={{ width: "200px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <img src="/app-logo.png" width="auto" height="25px" />
        </div>
        <h4 className="title">Header</h4>
      </div>
      {/* <div className="nav-right" style={{justifyContent:"space-between",width:"80%"}}> */}
       
        <div>
        <BellOutlined className="nav-icon" style={{ marginRight: "20px" }} />

        <SettingOutlined className="nav-icon" style={{ marginRight: "20px" }} />
        <span style={{color:"#fff", marginRight: "20px"}}>
          {sessionUser.fullname}
        </span>
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Avatar size={35} src="/user-thumbnail.png" />
        </Dropdown>
        </div>
      {/* </div> */}
    </Header>
  );
};

export default HeaderNav;
