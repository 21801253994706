import { Button, Col, Input, Row, Tabs, Form, Radio, Image, Switch, Typography, Divider, message, Select, Table, Upload, AutoComplete } from 'antd'
import classes from "../mastercss/master.module.css";
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Truck } from 'src/domain/truckDataType';
import { ReferenceDetail } from 'src/domain/referenceDetailType';
import truckService from 'src/services/truckService';
import { showMessage } from 'src/utils';



interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    // isSave?: any;
}

const TruckDataDetailInfo: React.FC<IProps> = (props) =>{

    const { id, userId, username, station } = props;
    const { Option } = Select;
   
    const [form] = Form.useForm();
    const [truckBrand, setTruckBrand] = useState<any>();
    const [truckStyle, setTruckStyle] = useState<any>();
    const [truck, setTruck] = useState<Truck>({} as Truck);
    
    const [refTruckBrands, setRefTruckBrands] = useState<ReferenceDetail[]>([]);
    const [refTruckStyles, setRefTruckStyle] = useState<ReferenceDetail[]>([]);

    useEffect(() => {
        truckService.getTruckById(Number(id))
            .then((res) => {
                setTruck(res.data)
            })
        
            console.log(truck.registId,)

            truckService.getTruckBrandList().then((res)=>{
                setRefTruckBrands(res.data)
            })

            truckService.getTruckStyleList().then((res)=>{
                setRefTruckStyle(res.data)
            })  

        // truckService.getAllTruckType().then((res) => {
        //     setTrucktypes(res.data)
        // })
    }, []);

    useEffect(() => {
        
        if (id === 0) {

        } else {
         
            form.setFieldsValue({
                
                modelYear: truck.modelYear ,
                buyYear: truck.buyYear ,
                useYear: truck.useYear ,
                style: truck.style ,
                model: truck.model ,
                color: truck.color ,
                brand: truck.brand ,
                brandMotor: truck.brandMotor ,
                motorNo: truck.motorNo ,
                chassisNo: truck.chassisNo ,
            });

            console.log(form);
        }

    }, [truck]);


    const onFinish = async (values: any) => {
        console.log("Succes :", values);
        const _truck: Truck = {
        
            id : id,
            registId: truck.registId,
            truckTypeId: truck.truckTypeId,
            partnerUId : truck.partnerUId,
            driverUId : truck.driverUId,
            status : truck.status,

            modelYear: values.modelYear,
            buyYear: values.buyYear,
            useYear: values.useYear,
            // style: truckStyle,
            style: values.style,
            model: values.model,
            color: values.color,
            // brand: truckBrand,
            brand: values.brand,
            brandMotor: values.brandMotor,
            motorNo: values.motorNo,
            chassisNo: values.chassisNo,

            userId: userId,
            username: username,

            updateUserid: userId,
            updateUsername: username,
            
        };

        console.log(_truck);

        if (id === 0) {
            try {
                const res = await truckService.addTruck(_truck);
                console.log(res);
                message.success("save truckdetail success", 1.5);             
            } catch (err) {
                showMessage("Something went wrong!");
                // setTrigger(false);
            }
        } else if (id !== 0) {
            try {
                const res = await truckService.updateTapTruckDetail(_truck);
                // history.replace("/app/truck/view/" + id);
                message.success("Update truckdetail Complete!", 1.5);
            } catch (err) {
                showMessage("Something went wrong!");
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };


    const onSelectBrand = (data: any) => {
        // console.log("onselect > ", data);
        // setTruckBrand(data.code);
        // let arr = data.split("/");
        // // console.log(arr[3])

        let rs = refTruckBrands.filter((item: any) =>
            item.name === data)

        setTruckBrand(rs[0].code);
        console.log("TruckBrand > ", rs[0].code);
        

    };

    const onSelectStyle = (data: any) => {
        // console.log("onSelectStyle > ", data);
        // setTruckBrand(data.code);
        // let arr = data.split("/");
        // // console.log(arr[3])

        let rs = refTruckStyles.filter((item: any) =>
            item.name === data)

        setTruckStyle(rs[0].code);
        console.log("style > ", rs[0].code);
        

    };

    

    return (
        <>
          <Form
                form={form}
                layout="horizontal"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
           >

                                <Row>
                                    <Col span={15}>

                                        <div className={classes.divAdd2}>
                                            <Row style={{ marginTop: "2em", }}>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ปีของรุ่น"
                                                        name="modelYear"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ปีที่ซื้อ"
                                                        name="buyYear"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ปีที่ใช้"
                                                        name="useYear"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ลักษณะ"
                                                        name="style"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                          {/* <AutoComplete
                                            onSelect={onSelectStyle}
                                            style={{ width: '70%' }}
                                            > */}
                                            <Select
                                               showSearch
                                               style={{ width: '100%' }}
                                               placeholder="Search to Select"
                                               optionFilterProp="children"
                                            >
                                             {refTruckStyles.map((item) =>
                                                <Option
                                                    value={item.code}
                                                    key={item.id}
                                                    label={item.name}>

                                                    {item.name}

                                                </Option>
                                            )} 
                                            </Select> 
                                        {/* </AutoComplete>   */}

                                                        {/* <Input placeholder="" /> */}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>


                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="แบบ/รุ่น"
                                                        name="model"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                     <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="สีรถ"
                                                        name="color"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ยี่ห้อรถ"
                                                        name="brand"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                           {/* <AutoComplete
                                            onSelect={onSelectBrand}
                                            style={{ width: '70%' }}
                                          > */}
                                          <Select
                                               showSearch
                                               style={{ width: '100%' }}
                                               placeholder="Search to Select"
                                               optionFilterProp="children"
                                            >
                                            {/* <AutoComplete
                                            onSelect={onSelectBrand}
                                            style={{ width: '70%' }}
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } > */}
                                           
                                             {refTruckBrands.map((item) =>
                                                <Option
                                                    value={item.code}
                                                    key={item.id}
                                                    label={item.name}>

                                                    {item.name}

                                                </Option>
                                            )} 
                                            {/* </AutoComplete> */}
                                       </Select>


                                                        {/* <Input.Search placeholder="" /> */}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>


                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ยี่ห้อเครื่องยนต์"
                                                        name="brandMotor"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                     <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เลขเครื่องยนต์"
                                                        name="motorNo"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เลขตัวถัง/โคลงคัทซี"
                                                        name="chassisNo"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                           
                                            <Row>
                                                <Col span={19}></Col>
                                                <Col span={4}>
                                                    <Button
                                                        size="middle"
                                                        type="primary"
                                                        icon={<SaveOutlined />}
                                                        onClick={() => form.submit()}
                                                    >
                                                        บันทึก
                                                    </Button>
                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={8}>
                                        <div className={classes.divAddImg}>
                                            <Row className={classes.divAddImg2}>
                                                <Col span={1}></Col>
                                                <Col span={22}  >
                                                    <Form.Item required tooltip="This is a required field"
                                                    >
                                                        <Image

                                                            width={250} height={130}
                                                            src="https://www.truckmarketthailand.com/asset/images/truck/10wheel.png" />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                            <Row style={{ marginTop: "1em" }}>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span >รหัส :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14}>

                                                            <span >9298430284</span>
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "1em", marginBottom: "1em" }}>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span>สถานะ :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14} >
                                                            <Switch />
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                            </Form>   
        </>
    )
}

export default TruckDataDetailInfo
