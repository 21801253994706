import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useState } from "react";
import classes from "./ModernAppLayout.module.css";
import styled from "styled-components";
import SideNavModule from "./SideNavModule";

const menuModules = [
  { id: 1, name: "overview", icon: "BlockOutlined" },
  { id: 2, name: "Post", icon: "BookOutlined" },
  { id: 3, name: "Counter", icon: "TableOutlined" },
  { id: 4, name: "Subject", icon: "TeamOutlined" },
];

const menus = (
  <Menu
    // onClick={this.handleClick}
    style={{ width: "225px" }}
    defaultSelectedKeys={["1"]}
    defaultOpenKeys={["sub1"]}
    mode="inline"
    className="sideNavMenuContainer"
  >
    <SubMenu key="sub1" icon={<MailOutlined />} title="Navigation One">
      <Menu.ItemGroup key="g1" title="Item 1">
        <Menu.Item key="1">Option 1</Menu.Item>
        <Menu.Item key="2">Option 2</Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g2" title="Item 2">
        <Menu.Item key="3">Option 3</Menu.Item>
        <Menu.Item key="4">Option 4</Menu.Item>
      </Menu.ItemGroup>
    </SubMenu>
    <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Navigation Two">
      <Menu.Item key="5">Option 5</Menu.Item>
      <Menu.Item key="6">Option 6</Menu.Item>
      <SubMenu key="sub3" title="Submenu">
        <Menu.Item key="7">Option 7</Menu.Item>
        <Menu.Item key="8">Option 8</Menu.Item>
      </SubMenu>
    </SubMenu>
    <SubMenu key="sub4" icon={<SettingOutlined />} title="Navigation Three">
      <Menu.Item key="9">Option 9</Menu.Item>
      <Menu.Item key="10">Option 10</Menu.Item>
      <Menu.Item key="11">Option 11</Menu.Item>
      <Menu.Item key="12">Option 12</Menu.Item>
    </SubMenu>
  </Menu>
);

const ModernAppLayout = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const showHandler = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.sideNav}>
        <SideNavModule showMenu={showHandler} menuModules={menuModules} />
        {showMenu && <div className={classes["sideNavMenu"]}>{menus}</div>}
      </div>

      <div style={{ backgroundColor: "#f1f2f7", width: "100%" }}>
        <div>header nav</div>
        <div>content</div>
      </div>
    </div>
  );
};

export default ModernAppLayout;
