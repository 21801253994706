import { Button, Col, Input, Row, Tabs, Form, Radio, Table,List, Image, Switch, Select, Typography, Divider, Space, AutoComplete } from 'antd'
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import partnerService from 'src/services/partnerService';
import { Truck } from 'src/domain/truckDataType';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    partnerUId ?:any;
    // isSave?: any;
}

const columnPartnerTruck = [
    {
        title: 'ลำดับ',
        dataIndex: 'seq',
    },
    {
        title: 'ชนิดรถ',
        dataIndex: 'truckTypeName',
    },
    {
        title: 'ทะเบียนรถ',
        dataIndex: 'registId',
    },
    {
        title: 'พนักงานขับรถ',
        dataIndex: 'driverName',
    },
    {
        title: 'สถานะ',
        dataIndex: 'status',
    },
   
];

const PartnerTruckInfo: React.FC<IProps> = (props)  => {

    const { id, userId, username, station ,partnerUId} = props;
    const [truckList, setTruckList] = useState<Truck[]>([]);

    useEffect(() => {

        partnerService.getTruckListToPartner(partnerUId)
        .then((res) => {
            setTruckList(res.data)
        })
    }, []);

    return (
        <>
           <Row>
                                <Col span={24}>
                                    {/* <div className={classes.divAdd2}
                        style={{ width: "98%", height: "500px" }}
                    > */}
                                    <div className={classes.divListDetail} >
                                        <Row >
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <Table
                                                 columns={columnPartnerTruck}
                                                rowKey={(item: Truck) => item.id}
                                                dataSource={truckList}

                                                >

                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>  
        </>
    )
}

export default PartnerTruckInfo
