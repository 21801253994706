import { Button, Col, Input, Row, Tabs, Form, Radio, Image, Switch, Typography, Divider, message, Select, Table, Upload, DatePicker, InputNumber, Anchor } from 'antd'
import classes from "../mastercss/master.module.css";
import { SaveOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { TruckTax } from 'src/domain/truckTaxType';
import truckService from 'src/services/truckService';
import { showMessage } from 'src/utils';

const { Link } = Anchor;

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    // isSave?: any;
}




const TruckTaxInfo: React.FC<IProps> = (props) =>{

    const { id, userId, username, station } = props;
    
    const truckId:any = id;
    const [taxId, setTaxId] = useState<any>(0);

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const [form] = Form.useForm();
    const [truckTax, setTruckTax] = useState<TruckTax>({} as TruckTax);
    const [trucktaxs, setTrucktaxs] = useState<TruckTax[]>([]);
    const [taxDateDate, setTaxDateDate] = useState<any>();
    const [expireDateDate, setExpireDateDate] = useState<any>();

    function format(num:number) {
          return num.toString().replace(/^[+-]?\d+/, function(int) {
          return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        });
      }
     
      useEffect(() => {
        truckService.getTruckTaxList(truckId).then((res)=>{
            setTrucktaxs(res.data)
        }) 
        
        console.log(trucktaxs)

    }, []);
    

    // useEffect(() => {
        
    //     if (truckTax === undefined) {

    //     } else {
         
    //         form.setFieldsValue({
                
    //             id: truckTax.id ,
    //             truckId: truckTax.truckId ,
    //             taxCode: truckTax.taxCode ,
    //             taxDate: truckTax.taxDate ,
    //             expireDate: truckTax.expireDate ,
    //             taxRate: truckTax.taxRate ,
    //             fineAmt:  truckTax.fineAmt,
    //             remark: truckTax.remark ,
    //         });

    //         console.log(form);
    //     }

    // }, [truckTax]);

    const onFinish = async (values: any) => {
        console.log("Succes :", values);
        const _truckTax: TruckTax = {
            id: taxId,
            truckId: id,
            taxCode: values.taxCode,
            taxDate:  moment(values.taxDate).format('YYYYMMDD'),//taxDateDate,
            expireDate: moment(values.expireDate).format('YYYYMMDD'),
            taxRate:   values.taxRate,
            fineAmt:   values.fineAmt,
            remark :  values.remark,
            
            userId: userId,
            username: username,

        };

        console.log("_truckTax >> ",_truckTax)
 
        if (trucktaxs.length === 0) {
            try {
                const res = await truckService.addTapTruckTax(_truckTax);
                console.log(res);
                // history.replace("/app/truck/list");
                message.success("New truck Complete!", 1.5);

                // setTrigger(false);
            } catch (err) {
                showMessage("Something went wrong!");
                // setTrigger(false);
            }
        } else if (trucktaxs.length !== 0) {
            try {
                const res = await truckService.updateTapTruckTax(_truckTax);
                // history.replace("/app/truck/view/" + id);
                message.success("Update truck Complete!", 1.5);
                
                 // setTrigger(false);
            } catch (err) {
                showMessage("Something went wrong!");
                // setTrigger(false);
            }
        }

        onClearTruckTax();

        truckService.getTruckTaxList(truckId).then((res)=>{
            setTrucktaxs(res.data)
        })  
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
        // setTrigger(false);
    };


    const getDataTaxDeate = (date:any) => {
        console.log('date', date);
        console.log("date",moment(date).format('YYYYMMDD'));   
        console.log("time",moment(date).format('HH:mm:ss'));

        // getDataTaxDeate(moment(date).format('YYYYMMDD'));
      }
    const getDataeExpireDate = (date:any) => {
        console.log('date', date);
        console.log("date",moment(date).format('YYYYMMDD'));
        console.log("time",moment(date).format('HH:mm:ss'));
        
        // setExpireDateDate(moment(date).format('YYYYMMDD'));
       
      } 

      const setDateMoment = (value:any) => {
          console.log(value)
        let  year =  value.substring(0,4)
        let  month = value.substring(4,6)
        let  day = value.substring(6,8)
        console.log( year+"/"+ month +"/"+day)
        return day+"/"+ month +"/"+year
      }

       const onSelectTruckTax = async (value: any) => {
       

        let taxDateMoment = setDateMoment(value.taxDate)
        let expireDateMoment = setDateMoment(value.expireDate)

        console.log("onSelectTruckTax" ,value);
        setTruckTax(value);
        // const res = await truckService.getTruckTaxById(value.id)
        

        //  var a = (value.fineAmt).toLocaleString('en')            // for numeric input
	    // var b = parseFloat("7800089.89").toLocaleString('en')  // for string input

	    //     console.log(a);
	    //     console.log(b);
        setTaxId(value.id);

        form.setFieldsValue({
                
             id: value.id ,
             truckId: value.truckId ,
             taxCode: value.taxCode ,
             taxDate: moment(taxDateMoment, dateFormatList),//value.taxDate 
             expireDate: moment(expireDateMoment, dateFormatList) ,
             taxRate:  value.taxRate ,
             fineAmt:  value.fineAmt,
             remark: value.remark ,
            
        });
        console.log("onSelectTruckTaxForm",form.getFieldsValue)
      }


      const onClearTruckTax = () => {
        
        form.setFieldsValue({
                
            id: "" ,
            truckId: "" ,
            taxCode: "" ,
            taxDate: "" ,
            expireDate: "" ,
            taxRate: "" ,
            fineAmt:  "",
            remark: "" ,
            
        });

        console.log(form);
      }

    
      const columnfilesTax = [
      
        {
            title: 'ลำดับ',
            dataIndex: 'id',
        },
        {
            title: 'เลขที่',
            dataIndex: 'taxCode',
        },
        {
            title: 'วันหมดอายุ',
            dataIndex: 'expireDate',
        },
        {
            title: 'อัตราภาษี',
            dataIndex: 'taxRate',
        },
        {
            title: 'ค่าปรับ',
            dataIndex: 'fineAmt',
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'remark',
        },
        {
            render: ((_:any, row:any) => <EditOutlined  onClick={() => onSelectTruckTax(row)}/>) 
        }
    
    ];

    return (
        <>
           <Form
                                form={form}
                                layout="horizontal"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >

                                <Row
                                // style={{ border: "1.5px solid #706d6d" }}
                                >
                                    <Col span={15}>

                                        <div className={classes.divAdd2}>
                                            <Row style={{ marginTop: "2em", }}>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เลขที่"
                                                        name="taxCode"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="วันที่เสียภาษี"
                                                        name="taxDate"
                                                    
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <DatePicker 
                                                        //    defaultValue={moment()} 

                                                        // defaultValue={moment('20150101', dateFormatList)}

                                                           format={dateFormatList}
                                                           onChange={getDataTaxDeate} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="วันที่หมดอายุ"
                                                        name="expireDate"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <DatePicker 
                                                        // defaultValue={moment()} 
                                                        format={dateFormatList} 
                                                        onChange={getDataeExpireDate}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item 
                                                        // style={{textAlign:"right"}}
                                                        label="อัตราภาษี"
                                                        name="taxRate"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                     
                                                       {/* <Input type="number"
                                                          style={{textAlign: 'right'}} 
                                                        //   defaultValue={"1000.00"} 
                                                         />    */}
                                                      <InputNumber 
                                                        className={classes.inputnumberRight}
                                                        style={{ width: 200  }}
                                                        defaultValue={Number(0.00)}
                                                        min={0}
                                                        step={0.01}
                                                         formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        //  parser={value =>  parseFloat(`${value}`.toFixed(2),''))}
                                                           parser={value => parseFloat(`${value}`.replace(/\$\s?|(,*)/g, ''))}
                                                        
                                                      />
                                                        
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item 
                                                        label="ค่าปรับ"
                                                        name="fineAmt"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                         <InputNumber 
                                                         style={{ width: 200 , textAlign : 'right' }}
                                                        defaultValue={Number(0.00)}
                                                        min={0}
                                                         formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        //  parser={value =>  parseFloat(`${value}`.toFixed(2),''))}
                                                          parser={value => parseFloat(`${value}`.replace(/\$\s?|(,*)/g, ''))}
                                                        
                                                      />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="หมายเหตุ"
                                                        name="remark"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row>
                                                <Col span={19}></Col>
                                                <Col span={4}>
                                                    <Button
                                                        size="middle"
                                                        type="primary"
                                                        icon={<SaveOutlined />}
                                                         onClick={() => form.submit() }
                                                    >
                                                        บันทึก
                                                    </Button>
                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={8}>
                                        <div className={classes.divAddImg}>
                                            <Row className={classes.divAddImg2}>
                                                <Col span={1}></Col>
                                                <Col span={22}  >
                                                    <Form.Item required tooltip="This is a required field"
                                                    >
                                                        <Image

                                                            width={250} height={130}
                                                            src="https://www.truckmarketthailand.com/asset/images/truck/10wheel.png" />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                            <Row style={{ marginTop: "1em" }}>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span >รหัส :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14}>

                                                            <span >9298430284</span>
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "1em", marginBottom: "1em" }}>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span>สถานะ :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14} >
                                                            <Switch />
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>


                                <Row
                                // style={{ border: "1.5px solid #706d6d"  }}
                                >
                                    <Col span={24} style={{ textAlign: "center" }} className={classes.divAdd2}>
                                       
                                    </Col>
                                </Row>

                            </Form>

                            <div>
                            <Table
                                            rowKey={(item: TruckTax) => item.id}
                                            columns={columnfilesTax}
                                            dataSource={trucktaxs} 
                                        >

                                        </Table>
                            </div>
        </>
    )
}

export default TruckTaxInfo
