import { Button, Col, Input, Row, Tabs, Form, Radio, List, Image, Switch, Typography, Divider, Space } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import Avatar from 'antd/lib/avatar/avatar';
import classes from "../owner/master.module.css";
import { Anchor } from 'antd';
import imageDriver from "../../assets/images/driver.jpg"

const { Link } = Anchor;
const { TabPane } = Tabs;
const { TextArea } = Input;



function callback(key: any) {
    console.log(key);
}




const DriverAdd = () => {
    return (
        <>
            <PageHeader title="เพิ่มข้อมูลพนักงานขับรถ"></PageHeader>

            <Content className="app-page-content" >

                <div className={classes.divAdd} >


                    <div style={{
                        padding: "20px",
                        width: "100%",
                    }}>

                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="ข้อมูล" key="1">

                                <Form
                                // form={form}
                                // layout="vertical"
                                // initialValues={{ requiredMarkValue: requiredMark }}
                                // onValuesChange={onRequiredTypeChange}
                                // requiredMark={requiredMark}
                                >
                                    <Row>
                                        <Col span={15}>
                                            <div className={classes.divAdd2}>

                                                <Row style={{ marginTop: "2em", }}>
                                                    <Col span={1}></Col>
                                                    <Col span={12}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            ชื่อเจ้าของรถ
                                                            <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                    <Col span={9}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            เบอร์โทรศัพท์
                                                            <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>

                                                <Row>
                                                    <Col span={1}></Col>
                                                    <Col span={22}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            ที่อยู่
                                                            <TextArea rows={2} />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>

                                                <Row>
                                                    <Col span={1}></Col>
                                                    <Col span={11}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            ตำบล
                     <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                    <Col span={10}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            อำเภอ
                    <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>

                                                <Row>
                                                    <Col span={1}></Col>
                                                    <Col span={11}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            จังหวัด
                     <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                    <Col span={10}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            รหัสใปรษรีย์
                    <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>

                                                <Row>
                                                    <Col span={1}></Col>
                                                    <Col span={11}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            เลขที่ผู้เสียภาษี
                     <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                    <Col span={10}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            อีเมล์
                    <Input placeholder="" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>
                                                <Row
                                                //  style={{ marginTop: "1em", marginLeft: "1em" }}
                                                >
                                                    <Col span={19}></Col>
                                                    <Col span={4}>

                                                        <Button
                                                            size="middle"
                                                            type="primary"
                                                            icon={<SaveOutlined />}
                                                        // style={{ marginTop: "1em", marginLeft: "1em" }}
                                                        // shape="round"
                                                        // onClick={() => history.push("/app/order/new")}
                                                        >
                                                            บันทึก
      </Button>
                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>
                                            </div>

                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={8}>
                                            <div className={classes.divAddImg}
                                            // style={{
                                            //         // border: "1.5px solid #706d6d",
                                            //         borderRadius: "15px",
                                            //         padding: "5px",
                                            //         width: "97%",
                                            //         marginTop: "1em",
                                            //         background: "#fbfcfd",
                                            //     }}
                                            >

                                                <Row className={classes.divAddImg2}
                                                // style={{

                                                //      marginLeft: "1em",
                                                //      marginRight: "1em",
                                                //      marginTop: "1em",
                                                //      marginBottom: "1em",

                                                // }}
                                                >
                                                    <Col span={1}></Col>
                                                    <Col span={22} style={{ marginTop: "1em", alignItems: "center" }} >
                                                        {/* <Form.Item required tooltip="This is a required field"> */}
                                                        <Image style={{ height: "400px", width: "300px" }} src={imageDriver} />
                                                        {/* </Form.Item> */}

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>
                                                <Row style={{ marginTop: "1em" }}>
                                                    <Col span={24}>

                                                        <Row>

                                                            <Col span={7} style={{ textAlign: "right" }}>
                                                                <span >รหัส :</span>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col span={14}>

                                                                <span >9298430284</span>
                                                            </Col>
                                                            <Col span={2}></Col>
                                                        </Row>
                                                        <Row style={{ marginTop: "1em", marginBottom: "1em" }}>

                                                            <Col span={7} style={{ textAlign: "right" }}>
                                                                <span>สถานะ :</span>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col span={14} >
                                                                <Switch />
                                                            </Col>
                                                            <Col span={2}></Col>
                                                        </Row>

                                                    </Col>

                                                </Row>
                                                {/* <Form.Item label="Switch" valuePropName="checked">
                                                    <Typography >รหัส 9298430284 </Typography>
                                                </Form.Item>
                                                <Form.Item label="Switch" valuePropName="checked">
                                                    <Switch />
                                                </Form.Item> */}
                                            </div>
                                        </Col>
                                    </Row>

                                </Form>

                            </TabPane>
                            <TabPane tab="บัญชีผู้ใช้" key="2">
                                <Form
                                // form={form}
                                // layout="vertical"
                                // initialValues={{ requiredMarkValue: requiredMark }}
                                // onValuesChange={onRequiredTypeChange}
                                // requiredMark={requiredMark}
                                >

                                    <Row>
                                        <Col span={15}>
                                            <div className={classes.divAdd2}>

                                                <Row style={{ marginTop: "2em", }}>
                                                    <Col span={4}></Col>
                                                    <Col span={16}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            ชื่อผู้ใช้
                    <Input placeholder="" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}></Col>
                                                </Row>
                                                <Row  >
                                                    <Col span={4}></Col>
                                                    <Col span={16}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            username
                   <Input placeholder="" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}></Col>
                                                </Row>

                                                <Row style={{ marginTop: "2em" }} >
                                                    <Col span={4}></Col>
                                                    <Col span={16}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            password
                    <Input placeholder="" type="password" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}></Col>
                                                </Row>


                                                <Row  >
                                                    <Col span={4}></Col>
                                                    <Col span={16}>
                                                        <Form.Item required tooltip="This is a required field">
                                                            confirm password
                    <Input placeholder="" type="password" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}></Col>
                                                </Row>

                                                <Row style={{ marginBottom: "1em" }}>
                                                    <Col span={19}></Col>
                                                    <Col span={4}>

                                                        <Button
                                                            size="middle"
                                                            type="primary"
                                                            icon={<SaveOutlined />}
                                                        // style={{ marginTop: "1em", marginLeft: "1em" }}
                                                        // shape="round"
                                                        // onClick={() => history.push("/app/order/new")}
                                                        >
                                                            บันทึก
      </Button>
                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>
                                            </div>

                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={8}>
                                            <div className={classes.divAddImg} >

                                                <Row className={classes.divAddImg2}>
                                                    <Col span={1}></Col>
                                                    <Col span={22} style={{ marginTop: "1em", alignItems: "center" }} >
                                                        {/* <Form.Item required tooltip="This is a required field"> */}
                                                        <Image width={150} src={imageDriver} />
                                                        {/* </Form.Item> */}

                                                    </Col>
                                                    <Col span={1}></Col>
                                                </Row>
                                                <Row style={{ marginTop: "1em" }}>
                                                    <Col span={24}>

                                                        <Row>

                                                            <Col span={7} style={{ textAlign: "right" }}>
                                                                <span >รหัส :</span>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col span={14}>

                                                                <span >9298430284</span>
                                                            </Col>
                                                            <Col span={2}></Col>
                                                        </Row>
                                                        <Row style={{ marginTop: "1em", marginBottom: "1em" }}>

                                                            <Col span={7} style={{ textAlign: "right" }}>
                                                                <span>สถานะ :</span>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col span={14} >
                                                                <Switch />
                                                            </Col>
                                                            <Col span={2}></Col>
                                                        </Row>

                                                    </Col>

                                                </Row>
                                                {/* <Form.Item label="Switch" valuePropName="checked">
                                                    <Typography >รหัส 9298430284 </Typography>
                                                </Form.Item>
                                                <Form.Item label="Switch" valuePropName="checked">
                                                    <Switch />
                                                </Form.Item> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                        </Tabs>
                    </div>

                </div>

            </Content>

        </>
    )
}

export default DriverAdd
