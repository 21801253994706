
import { Button, Modal, Menu, Table, Pagination, Tag, Row, Col, Dropdown, Space, Form, Radio, RadioChangeEvent } from 'antd';
import Search from 'antd/lib/input/Search';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import classes from "../mastercss/jobrenger.module.css";
import { JobRengerMove } from 'src/domain/jobRengerMoveType';
import { RouteComponentProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import customerBusinessService from "src/services/customerBusinessService";
// import BusinessDataInfo from './BusinessDataInfo';
import { useAppSelector } from "../../store";
import { SessionUser } from 'src/store/authen/types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import SearchDatePopup from './SearchDatePopup';
import { FilterItem } from './filterItem';
import SearchJoborderPopup from './SearchJoborderPopup';
import JobRengerMoveService from 'src/services/JobRengerMoveService';
import SearchJobStatusPopup from './SearchJobStatusPopup';
import SearchTruckRegistPopup from './SearchTruckRegistPopup';

interface IParams {
  id: string;
  userId: string;
  username: string;
  station: string;
  // userUId: string;
  // partnerUId ?: any;
  // sessionUser : SessionUser;
}




const JobRengerMoveList: React.FC<RouteComponentProps<IParams>> = (props) => {

  const { sessionUser } = useAppSelector((state) => state.auth)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const history = useHistory();
  const [jobRengerMoveDataList, setJobRengerMoveDataList] = useState<JobRengerMove[]>([]);
  const [searchData, setSearchData] = useState<any>("");
  const [page, setPage] = useState(1);

  const [dateVisble, setDateVisible] = useState(false);
  const [jobVisble, setJobVisible] = useState(false);
  const [jobStatusVisble, setJobStatusVisble] = useState(false);

  const [truckRegistVisble, setTruckRegistVisble] = useState(false);
  const [jobType, setJobType] = useState<"LONG-HAUL" | "DISTRIBUTE">("LONG-HAUL");

  const [filterList, setFilterList] = useState<FilterItem[]>([]);




  useEffect(() => {

    // customerBusinessService.getCustomerBusiinessByCodition("")
    //     .then((res) => {
    //         console.log(res.data);
    //         setBusinessList(res.data)
    //     })
    // console.log("businessList >>> " + businessList,)

    const filterItem: FilterItem = {
      name: "jobType",
      payload: {
        jobType: jobType
      },
      message: "",
      isHidden: true
    }
    handleSearchJobType(filterItem);

  }, []);

  useEffect(() => {
    if (filterList.length > 0) actionFilter();

  }, [filterList]);


  const onSearch = (value: any) => {
    // setSearchData(value);
    // customerBusinessService.getCustomerBusiinessByCodition(value)
    //     .then((res) => {
    //         setBusinessList(res.data)
    //     })
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const columns = [

    {

      title: 'ลำดับ',
      dataIndex: 'seq',
       render: (value: string, item: JobRengerMove, index: number) => ((page - 1) * 10 + index)+1 ,
    },
    {
      title: 'วันที่จอง',
      dataIndex: 'jobDateStr',
      // render: (value: string, item: JobRengerMove, index: number) => <Link to={`/app/business/edit/${item.id}`}>{value}</Link>,
    },
    {
      title: 'วันที่ใช้งาน',
      dataIndex: 'jobStartDateStr',
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'jobEndDateStr',
    },
    {
      title: 'เลขที่ใบงาน',
      dataIndex: 'jobNo',
      render: (value: string, item: JobRengerMove, index: number) => <Link to={`/app/jobrenger/view/${item.jobNo}`}>{value}</Link>
    },

    {
      title: 'ทะเบียนรถ',
      dataIndex: 'truckRegistId',
    },
    {
      title: 'ชื่อ พขร หลัก',
      dataIndex: 'driverFirstName',
    },
    {
      title: 'ชื่อ พขร สำรอง',
      dataIndex: 'driverSecondName',
    },
    {
      title: 'ระยะทาง',
      dataIndex: 'distance',
    },
    {
      title: 'ราคาเที่ยววิ่ง',
      dataIndex: 'salePriceAmt',
    },
    {
      title: 'เลขที่อ้างอิง',
      dataIndex: 'partnerJobRefNo',
    },
    {
      title: 'บริษัท',
      dataIndex: 'partnerName',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'jobDesc',
    },
    {
      title: 'สถานะ',
      dataIndex: 'statusStr',
    },
  ];

  const actionFilter = () => {

    console.log("actionFilter")

    JobRengerMoveService.search(filterList).then((res) => {
      console.log(res.data);
      setJobRengerMoveDataList(res.data);
    })

    console.log(jobRengerMoveDataList);
  }

  const handleSearchDate = (filterItem: FilterItem) => {
    setDateVisible(false);
    console.log("date filterItem ", filterItem)
    // setFilterList([...filterList,filterItem]);
    const _filterList = [...filterList];
    console.log("_filterList A", _filterList);

    const item = _filterList.filter(item => item.name === 'date');

    if (item.length === 0) {
      setFilterList([...filterList, filterItem]);
    } else {

      _filterList.forEach(item => {
        if (item.name === 'date') {
          item.message = filterItem.message;
          item.payload = filterItem.payload;
        }
      })

      console.log("_filterList b", _filterList);
      setFilterList([..._filterList]);
    }

    // actionFilter();

  }


  const handleSearchJoborder = (filterItem: FilterItem) => {
    setJobVisible(false);
    console.log("joborder filterItem ", filterItem)
    // setFilterList([...filterList,filterItem]);
    const _filterList = [...filterList];

    const item = _filterList.filter(item => item.name === 'joborder');

    if (item.length === 0) {
      setFilterList([...filterList, filterItem]);
    } else {

      _filterList.forEach(item => {
        if (item.name === 'joborder') {
          item.message = filterItem.message;
          item.payload = filterItem.payload;
        }
      })

      console.log("_filterList b", _filterList);
      setFilterList([..._filterList]);
    }

    // actionFilter();
  }

  const handleDeleteItem = (name: string) => {
    const _filterList = [...filterList];

    const item = _filterList.filter(item => item.name === name);
    if (item.length === 0) return;


    const _item = _filterList.filter(item => item.name !== name);
    setFilterList([..._item]);

  }

  const dateSearch = (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchDatePopup
        onOK={handleSearchDate} ></SearchDatePopup>
    </div>
  );

  const handleSearchStatus = (filterItem: FilterItem) => {

    setJobStatusVisble(false);
    console.log("status filterItem ", filterItem)
    // setFilterList([...filterList,filterItem]);
    const _filterList = [...filterList];

    const item = _filterList.filter(item => item.name === 'status');

    if (item.length === 0) {
      setFilterList([...filterList, filterItem]);
    } else {

      _filterList.forEach(item => {
        if (item.name === 'status') {
          item.message = filterItem.message;
          item.payload = filterItem.payload;
        }
      })

      console.log("_filterList status", _filterList);
      setFilterList([..._filterList]);
    }

  }

  const handleSearchTruckRegist = (filterItem: FilterItem) => {
    setTruckRegistVisble(false);
    console.log("truckregist ", filterItem)
    // setFilterList([...filterList,filterItem]);
    const _filterList = [...filterList];

    const item = _filterList.filter(item => item.name === 'truckRegist');

    if (item.length === 0) {
      setFilterList([...filterList, filterItem]);
    } else {

      _filterList.forEach(item => {
        if (item.name === 'truckRegist') {
          item.message = filterItem.message;
          item.payload = filterItem.payload;
        }
      })

      console.log("_filterList b", _filterList);
      setFilterList([..._filterList]);
    }

    // actionFilter();
  }

  const handleSearchJobType = (filterItem: FilterItem) => {
    setJobVisible(false);
    console.log("joborder filterItem ", filterItem)
    // setFilterList([...filterList,filterItem]);
    const _filterList = [...filterList];

    const item = _filterList.filter(item => item.name === 'jobType');

    if (item.length === 0) {
      setFilterList([...filterList, filterItem]);
    } else {

      _filterList.forEach(item => {
        if (item.name === 'jobType') {
          item.message = filterItem.message;
          item.payload = filterItem.payload;
        }
      })

      console.log("_filterList b", _filterList);
      setFilterList([..._filterList]);
    }

    // actionFilter();
  }

  const statusSearch = (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchJobStatusPopup
        onOK={handleSearchStatus} ></SearchJobStatusPopup>
    </div>
  );

  const joborderSearch = (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchJoborderPopup
        onOK={handleSearchJoborder} ></SearchJoborderPopup>
    </div>
  );

  const truckRegistSearch = (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchTruckRegistPopup
        onOK={handleSearchTruckRegist} ></SearchTruckRegistPopup>
    </div>
  );

  const optionsRadio = [
    { label: 'วิ่งระยะไกล', value: 'LONG-HAUL' },
    { label: 'วิ่งกระจาย', value: 'DISTRIBUTE' },
  ];

  const onChangeJobType = (event: RadioChangeEvent) => {
    console.log('onChangeJobType', event.target.value);
    setJobType(event.target.value);

    const filterItem: FilterItem = {
      name: "jobType",
      payload: {
        jobType: event.target.value,
      },
      message: "",
      isHidden: true
    }

    handleSearchJobType(filterItem);
  };

  return (
    <>
      <PageHeader title="โปรแกรมออกใบสั่ง"></PageHeader>

      <Content className="app-page-content" >
        <div style={{}}>
          <Row style={{ paddingTop: '10px' }} >
            <Col span={4} style={{}}><div ><FilterOutlined /> ตัวกรอง </div> </Col>
            <Col span={4} style={{}}>
              <div >
                <Dropdown overlay={dateSearch} visible={dateVisble} className={classes.overlaySearch} trigger={['click']}>
                  <span onClick={e => { e.preventDefault();
                     setDateVisible(prev => !prev);
                     setJobVisible(false);
                     setJobStatusVisble(false);
                     setTruckRegistVisble(false); }}>
                    <Space>
                      วันที่
                      {dateVisble ? <UpOutlined /> : <DownOutlined />}
                    </Space>
                  </span>
                </Dropdown>
              </div>
            </Col>
            <Col span={4} style={{}}>
            {/* overlayStyle={{ padding: '10px', backgroundColor: '#DDD' }} */}
              <Dropdown overlay={truckRegistSearch} visible={truckRegistVisble} className={classes.overlaySearch} trigger={['click']}>
                <span onClick={e => { e.preventDefault(); 
                  setTruckRegistVisble(prev => !prev) 
                  setJobVisible(false);
                  setJobStatusVisble(false);
                  setDateVisible(false);}}>
                  <Space>
                    ทะเบียนรถ
                    {jobVisble ? <UpOutlined /> : <DownOutlined />}
                  </Space>
                </span>
              </Dropdown>
            </Col>
            <Col span={4} style={{}}>
              <div >
                <Dropdown overlay={joborderSearch} visible={jobVisble} className={classes.overlaySearch} trigger={['click']}>
                  <span onClick={e => { e.preventDefault(); 
                    setJobVisible(prev => !prev);
                    setTruckRegistVisble(false);
                    setJobStatusVisble(false);
                    setDateVisible(false); }}>
                    <Space>
                      เลขที่ใบงาน
                      {jobVisble ? <UpOutlined /> : <DownOutlined />}
                    </Space>
                  </span>
                </Dropdown>

              </div>

            </Col>
            <Col span={4} style={{}}>
              <div >
                <Dropdown overlay={statusSearch} visible={jobStatusVisble} className={classes.overlaySearch} trigger={['click']}>
                  <span onClick={e => { e.preventDefault(); 
                    setJobStatusVisble(prev => !prev);
                    setTruckRegistVisble(false);
                    setJobVisible(false);
                    setDateVisible(false); }}>
                    <Space>
                      สถานะ
                      {jobStatusVisble ? <UpOutlined /> : <DownOutlined />}
                    </Space>
                  </span>
                </Dropdown>

              </div>
            </Col>
            <Col span={4} style={{}}>
              {/* <a>วิ่งระยะไกล</a> | <a>วิ่งกระจาย</a> */}
              <Radio.Group
                options={optionsRadio}
                onChange={onChangeJobType}
                value={jobType}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
        </div>
        <div>
          <ul>
            {
              filterList.map(item => (item.isHidden === undefined || !item.isHidden) && (
                <li key={item.name}>{item.message} - <span onClick={() => handleDeleteItem(item.name)} >ลบ</span> </li>
              ))
            }
          </ul>
        </div>
        <div style={{ textAlign: 'left' }}>

          <Button
            size="small"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
                console.log("jobType", jobType)
                history.push("/app/jobrenger/form/"+jobType)
              }
            }
          // onClick={() => setIsModalVisible(true)}
          >
            เพิ่ม
          </Button>
        </div>
        <div>
          <Table style={{
            marginTop: "1em",
          }}

            rowKey={(item: JobRengerMove) => item.id}
            dataSource={jobRengerMoveDataList}
            columns={columns}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
          // pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '15']}}
          />
        </div>
      </Content>

    </>
  )
}

export default JobRengerMoveList