import { Button, Col, Input, Row, Tabs, Form, Radio, Table,List, Image, Switch, Select, Typography, Divider, Space, AutoComplete } from 'antd'
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import { Job } from 'src/domain/rangerJobType';
import partnerService from 'src/services/partnerService';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    partnerUId ?:any;
    // isSave?: any;
}

const columnTruckHitorys = [
    {
        title: 'ลำดับ',
        dataIndex: 'seq',
    },
    {
        title: 'วันที่ใช้งาน',
        dataIndex: 'startDate',
    },
    {
        title: 'วันที่สิ้นสุดใช้งาน',
        dataIndex: 'endDate',
    },
    {
        title: 'เลขที่งาน',
        dataIndex: 'jobNo',
    },
    {
        title: 'ชนิดรถ',
        dataIndex: 'truckTypeName',
    },
    {
        title: 'ทะเบียนรถ',
        dataIndex: 'registId',
    },
];

export const PartnerTruckHistoryInfo : React.FC<IProps> = (props)  => {

    const { id, userId, username, station ,partnerUId} = props;
    const [rangerJobList, setRangerJobList] = useState<Job[]>([]);

    useEffect(() => {

        partnerService.getRangerJobListToPartner(partnerUId)
        .then((res) => {
            setRangerJobList(res.data)
        })
    }, []);

    return (
        <>
          <Row>
                                <Col span={24}>
                                    {/* <div className={classes.divAdd2}
                        style={{ width: "98%", height: "500px" }}
                    > */}
                                    <div className={classes.divListDetail} >
                                        <Row >
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <Table
                                                 columns={columnTruckHitorys}
                                                 rowKey={(item: Job) => item.id}
                                                 dataSource={rangerJobList}

                                                >

                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>  
        </>
    )
}

export default PartnerTruckHistoryInfo