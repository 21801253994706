import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import { Button, Descriptions, message, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Student } from "src/domain/studentType";
import studentService from "src/services/studentService";
import { showMessage } from 'src/utils'

interface IParams {
  id: string;
}

const ViewUser: React.FC<RouteComponentProps<IParams>> = (prop) => {
  const _id = Number(prop.match.params.id);
  const [student, setStudent] = useState<Student>({} as Student);
  const [active, setActive] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const _student = studentService.getStudentById(_id);
    setStudent(_student);
    setActive(_student.isActive);
    return () => { };
  }, []);

  const handleDelete = () => {
    const _student = setStudentData(student);
    try {
      studentService.updateStudent(_student);
      history.push("/app/student/list")
      message.success('Success, Update Complete', 1.5);
    } catch (err) {
      showMessage(err);
    }
  }

  const setStudentData = (values: any) => {
    const _student: Student = {
      id: _id,
      name: values.name,
      lastname: values.lastname,
      birthday: values.birthday,
      address: values.address,
      code: values.code,
      isActive: false,
    }
    return _student;
  }

  return (
    <>
      <PageHeader
        title={"Student: " + student.name + " (" + student.code + ")"}
        onClose={() => history.push("/app/student/list")}
      >
        <Space>
          <Button
            disabled={!active}
            size="small"
            type="default"
            icon={<EditOutlined />}
            onClick={() => history.push(`/app/student/edit/${student.id}`)}
          >
            Edit
          </Button>
          <Button
            disabled={!active}
            size="small"
            type="default"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
        </Space>
      </PageHeader>
      <Content className="app-page-content">
        <div style={{ padding: "0px 20px" }}>
          <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            title="Student Detail"
            size="middle"
            bordered
          >
            <Descriptions.Item label="Name">{student.name}</Descriptions.Item>
            <Descriptions.Item label="Lastname">{student.lastname}</Descriptions.Item>
            <Descriptions.Item label="Address" span={2}>{student.address}</Descriptions.Item>
            <Descriptions.Item label="Birthday">{student.birthday}</Descriptions.Item>
          </Descriptions>
        </div>
      </Content>
    </>
  );
};

export default ViewUser;
