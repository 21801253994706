import { Button, Col, Input, Row, Tabs, Form, Radio, Image, Switch, Typography, Divider, message, Select, Table, Upload } from 'antd'
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import truckService from 'src/services/truckService';
import { Truck } from 'src/domain/truckDataType';
import { useHistory } from 'react-router';
import { TruckType } from 'src/domain/truckSelectType';
import { Partner } from 'src/domain/partnerType';
import { showMessage } from 'src/utils';
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import { Driver } from 'src/domain/driverType';
import { ReferenceDetail } from 'src/domain/referenceDetailType';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    isSave?: any;
}

const TruckDataInfo: React.FC<IProps> = (props) => {

    const { id, userId, username, station, isSave } = props;
    // console.log("props" + props.id)

    const { Option } = Select;
    const [form] = Form.useForm();
    const history = useHistory();
    const [truck, setTruck] = useState<Truck>({} as Truck);
    const truckType: TruckType[] = [];
    const [trucktypes, setTrucktypes] = useState<TruckType[]>([]);

    const partner: Partner[] = [];
    const [partners, setPartners] = useState<Partner[]>([]);

    const driver: Driver[] = [];
    const [drivers, setDrivers] = useState<Driver[]>([]);

    const referenceDetail: ReferenceDetail[] = [];
    const [referenceDetails, setReferenceDetails] = useState<ReferenceDetail[]>([]);

    useEffect(() => {
        truckService.getTruckById(Number(id))
            .then((res) => {
                setTruck(res.data)
            })
        
            console.log(truck.registId,)

        truckService.getAllTruckType().then((res) => {
            setTrucktypes(res.data)
        })

    }, []);

    useEffect(() => {
        console.log("business >>>>", truck)
        if (id === 0) {

        } else {
         
            form.setFieldsValue({
                registId: truck.registId ,
                truckTypeId: truck.truckTypeId,
                partnerUId: truck.partnerUId,
                driverUId: truck.driverUId,
                status: truck.status,
            });


            truckService.getAllPartner().then((res) => {
                setPartners(res.data)
            })


            truckService.getDriverByPartnerUId(String(truck.partnerUId)).then((res) => {
                setDrivers(res.data)
            })

            truckService.getTruckStatus().then((res) => {
                setReferenceDetails(res.data)
            })

            console.log(form);
        }

    }, [truck]);


    const onFinish = async (values: any) => {
        console.log("Succes :", values);
        const _truck: Truck = {
        
            id : id,
            registId: values.registId,
            truckTypeId: values.truckTypeId,
            partnerUId : values.partnerUId,
            driverUId : values.driverUId,
            status : values.status,

            userId: userId,
            username: username,

        };

        console.log(_truck);

        if (id === 0) {
            try {
                const res = await truckService.addTruck(_truck);
                console.log(res);
                message.success("save success", 1.5);
                isSave(true);
                // history.replace("/app/truck/list");
                // message.success("New truck Complete!", 1.5);
                // setTrigger(false);
            } catch (err) {
                showMessage("Something went wrong!");
                // setTrigger(false);
            }
        } else if (id !== 0) {
            try {
                const res = await truckService.updateTruck(_truck);
                history.replace("/app/truck/view/" + id);
                message.success("Update truck Complete!", 1.5);
            } catch (err) {
                showMessage("Something went wrong!");
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    function handleChangeTruckType(values: number) {
        const _truckTypeId = values;
        console.log("truckType >>> ", values, _truckTypeId)

        truckService.getAllPartner().then((res) => {
            setPartners(res.data)
        })

        // truckService.getAllPartner(Number(id)).then((res)=> {
        //     console.log("getCapabilitiesByWorkerId",res.data)
        //     const caps:number[] = res.data.map((item:any)=>{

        //        return item.capabilityId ;
        //     });
        //     console.log("item" , caps);
        //     setWorkerCaps(caps);
        //   })

    }

    function handleChangePartner(value: string) {
        const _partnerUId = value;
        console.log("partnerUId >>> ", value, _partnerUId)

        truckService.getDriverByPartnerUId(value).then((res) => {
            setDrivers(res.data)
        })

    }

    function handleChangeDriver(value: string) {
        // const _partnerUId = value;
        // console.log("partnerUId >>> ", value, _partnerUId)

        truckService.getTruckStatus().then((res) => {
            setReferenceDetails(res.data)
        })

        console.log(referenceDetails)
    }

    

    return (
        <>
            <Form
                                form={form}
                                layout="horizontal"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >

                                <Row>
                                    <Col span={15}>

                                        <div className={classes.divAdd2}>
                                            <Row style={{ marginTop: "2em", }}>
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ทะเบียนรถ"
                                                        name="registId"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="ชนิดรถ"
                                                        name="truckTypeId"
                                                    // required tooltip="This is a required field"
                                                    >

                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            onChange={handleChangeTruckType}
                                                            filterOption={(input: any, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >

                                                            {trucktypes.map((item) =>
                                                                <Option value={item.id} key={item.id} label={item.typeName}>

                                                                    {item.typeName}

                                                                </Option>
                                                            )}

                                                        </Select>

                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>

                                            <Row >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="เจ้าของรถ"
                                                        name="partnerUId"
                                                    // required tooltip="This is a required field"
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            onChange={handleChangePartner}
                                                            filterOption={(input: any, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {/* <Option value="1">Not Identified</Option>
                                                            <Option value="2">Closed</Option>
                                                            <Option value="3">Communicated</Option>
                                                            <Option value="4">Identified</Option>
                                                            <Option value="5">Resolved</Option>
                                                            <Option value="6">Cancelled</Option> */}
                                                            {partners.map((item) =>
                                                                <Option value={item.partnerUId} key={item.id} label={item.name}>

                                                                    {item.name}

                                                                </Option>
                                                            )}

                                                        </Select>

                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="พนักงานขับรถ"
                                                        name="driverUId"
                                                    // required tooltip="This is a required field"
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Search to Select"
                                                            onChange={handleChangeDriver}
                                                            optionFilterProp="children"
                                                            filterOption={(input: any, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >

                                                            {drivers.map((item) =>
                                                                <Option value={item.driverUId} key={item.id} label={item.firstname+" "+item.lastname}>

                                                                    {item.firstname+" "+item.lastname}

                                                                </Option>
                                                            )}

                                                        </Select>

                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row  >
                                                <Col span={3}></Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        label="สถานะรถ"
                                                        name="status"
                                                    // required tooltip="This is a required field"
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                           
                                                        // filterOption={(input: any, option: any) =>
                                                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        // }
                                                        // filterSort={(optionA, optionB) =>
                                                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        // }
                                                        >

                                                            {referenceDetails.map((item) =>
                                                                <Option value={item.code} key={item.id} label={item.name}>

                                                                    {item.name}

                                                                </Option>
                                                            )}

                                                        </Select>

                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={19}></Col>
                                                <Col span={4}>
                                                    <Button
                                                        size="middle"
                                                        type="primary"
                                                        icon={<SaveOutlined />}
                                                        onClick={() => { form.submit() }}
                                                        //  onClick={() => setTrigger(true)}
                                                    >
                                                        บันทึก
                                                    </Button>
                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={8}>
                                        <div className={classes.divAddImg}>
                                            <Row className={classes.divAddImg2}>
                                                <Col span={1}></Col>
                                                <Col span={22}  >
                                                    <Form.Item required tooltip="This is a required field"
                                                    >
                                                        <Image

                                                            width={250} height={130}
                                                            src="https://www.truckmarketthailand.com/asset/images/truck/10wheel.png" />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={1}></Col>
                                            </Row>
                                            <Row style={{ marginTop: "1em" }}>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span >รหัส :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14}>

                                                            <span >9298430284</span>
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "1em", marginBottom: "1em" }}>
                                                        <Col span={7} style={{ textAlign: "right" }}>
                                                            <span>สถานะ :</span>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={14} >
                                                            <Switch />
                                                        </Col>
                                                        <Col span={2}></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
  
        </>
    )
}

export default TruckDataInfo
