import { Avatar, Button, Image, Tag,Table, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { Partner } from 'src/domain/partnerType';
import PageHeader from 'src/layouts/PageHeader';
import classes from "../mastercss/master.module.css";
import { PlusOutlined } from "@ant-design/icons";
import Search from 'antd/lib/input/Search';
import { useHistory } from 'react-router';

import patnerService from "src/services/partnerService";
import { Link } from 'react-router-dom';
import PartnerDataInfo from './PartnerDataInfo';


  const columns = [
  
    {

        title: 'ลำดับ',
        dataIndex: 'seq',


    },
    {

        title: 'ชื่อเจ้าของรถ',
        dataIndex: 'name',
        render: (value: string, item: Partner, index: number) => <Link to={`/app/partner/edit/${item.partnerUId}`}>{value}</Link>,

    },
    {
        title: 'เบอร์โทร',
        dataIndex: 'tel',
    },
    {
        title: 'เลขที่ผู้เสียภาษี',
        dataIndex: 'cropId',
    },
    {
      title: 'วงเงินประกัน',
      dataIndex: 'insuranceAmt',
  },
  {
    title: 'สถานะ',
    dataIndex: 'useFlg',
    render: (useFlg: number) => (
      <>
       <div>
          {(() => {
            if (useFlg ===  0 ) {
              return (
                <Tag color="green">{"ใช้งาน"}</Tag>
              )
            } else {
              return (
                <div style={{ background: "red" }}>
                  <Tag color="red">{"ไม่ใช้งาน"}</Tag>
                </div>
              )
            }
          })()}
        </div>       
      </>
    ),
  },


];
  
  
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Partner[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    // getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

const PartnerList = () => {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const history = useHistory();
    const [partnerList, setPartnerList] = useState<Partner[]>([]);
    const [searchData, setSearchData] = useState<any>("");
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');

    useEffect(() => {

      patnerService.getPartnerByCondition("")
      .then((res) => {
        setPartnerList(res.data)
      })
  }, []);

    const onSearch = (value: any) => {
      console.log("onsearch" ,value)
      
        patnerService.getPartnerByCondition(value)
        .then((res) => {
          setPartnerList(res.data)
        })
     
  }

    const handleOk = () => {
      setIsModalVisible(false);
  };

  const handleCancel = () => {
      setIsModalVisible(false);
  };


    return (
        <>
         <PageHeader title="ข้อมูลเจ้าของรถ">

            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
                เพิ่ม
            </Button>

        </PageHeader>

        <Content className="app-page-content" >

            <div className={classes.divList}

>
                <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200, marginLeft: "1em", }} />

                  <div className={classes.divListDetail}

            >


            <Table style={{
                 marginTop: "1em",
             }}

                  rowKey={(item: Partner) => item.id}
                  dataSource={partnerList}
                  columns={columns}
                  pagination={{ defaultPageSize: 10, showSizeChanger: true,}}
            />
            </div>
        </div>

</Content>

        <Modal title="เพิ่มข้อมูล เจ้าของรถ "
                visible={isModalVisible}
                footer={null}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
        >
        <div>
          <PartnerDataInfo
            id={0}
            userId={99}
            username={"username"}
            station={"11"}
            partnerUId={""}
            isSave={() => {
            console.log("onNextStep");
            handleCancel();
          }}
         >

        </PartnerDataInfo> 
</div>
</Modal>
        {/* <PageHeader title="ข้อมูลเจ้าของรถ">
            
           <Button 
             size="small"
             type="primary"
             icon={<PlusOutlined  />}
             onClick={() => history.push("/app/partner/new")}
           >
             เพิ่ม
           </Button>
          
         </PageHeader>
       
     <Content className="app-page-content" >
        
         <div   className={classes.divList} >
            <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200,marginLeft: "1em", }} />
       
            <div className={classes.divListDetail}>  
         
                <Table  
                    style={{
                        marginTop: "1em",
                    }}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={data} 
               // columns={columns}
               // dataSource={post}
               // size="small"
               // rowKey={(item: Post) => item.id}
                />
                </div>
           </div> 
         </Content>*/}
       </> 
    )
}

export default PartnerList
