const Sever = [
    {id:1, firstname:"Techin", lastname:"Nakate", address:"Chiang mai", position:"Stuff", idCard:"1234567890000"},
    {id:2, firstname:"Pichapa", lastname:"Tawichai", address:"Lamphun", position:"Stuff", idCard:"3501938483725"},
    {id:3, firstname:"Yaya", lastname:"Moon", address:"Bankok", position:"Stuff", idCard:"3298339838799"},
    {id:4, firstname:"Nadeach", lastname:"Kukimiya", address:"Bankok", position:"Stuff", idCard:"3204892492709"},
    {id:5, firstname:"Jame", lastname:"Mar", address:"Chiang mai", position:"Stuff", idCard:"1323214142424"},
];

const getOfficer = () => {
    return Sever;
};

const newOfficer = (data: any) =>{
    Sever.push(data);
    return newOfficer;
};

const getOfficerById = (id: number) => {
    let x = Sever.filter(item=> item.id === id);
    return x[0];
};

const updateOfficer = (data: any) => {
    let index = Sever.findIndex((item) => item.id === data.id);
    if(index !== -1){
        Sever.splice(index, 1); // remove
        Sever.splice(index, 0, data); // add new 
    } 
};

const deleteOfficer = (id: number) => {
    let index = Sever.findIndex((item) => item.id === id);
    if(index !== -1) Sever.splice(index, 1);
};

export default {
    getOfficer,
    newOfficer,
    getOfficerById,
    updateOfficer,
    deleteOfficer
}

