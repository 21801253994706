import { Button, Col, Input, Row, Tabs, Form, Radio, Table,List, Image, Switch, Select, Typography, Divider, Space, AutoComplete, Card, message } from 'antd'
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { SecUser } from 'src/domain/secUserType';
import { Partner } from 'src/domain/partnerType';
import partnerService from 'src/services/partnerService';
import { showMessage } from 'src/utils';
import PageHeader from 'src/layouts/PageHeader';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    partnerUId?:any;
    // isSave?: any;
}

const { Text } = Typography;

const PartnerUserInfo  : React.FC<IProps> = (props)  =>{

    const { id, userId, username, station ,partnerUId} = props;

    console.log(station);

    const history = useHistory();
    const [form] = Form.useForm();
    const [partner, setPartner] = useState<Partner>({} as Partner);
    const [secUser, setSecUser] = useState<SecUser>({} as SecUser);
    const [isUseflg, setUseflg] = useState<any>(false);
    const [useflgData, setUseflgData] = useState<any>(0);
    const [isCheckUsername, setCheckUsername] = useState<any>(false);
  
    const switchOnChange = (checked:any) => {
        console.log(`switch to ${checked}`);
        setUseflg(checked);
        if(checked === false){
            setUseflgData(-1)
        }else{
            setUseflgData(0)
        }
        console.log(useflgData);
    };

    

    useEffect(() => {
        
        //partnerUId
        //หา  USER_UID จาก sec_userPartner
        
        partnerService.getPartnerByPartnerUId(partnerUId)
        .then((res) => {
            setPartner(res.data)
        })

        partnerService.getSecUserByPartnerUId(partnerUId)
        .then((res) => {
            setSecUser(res.data);
        })
         
        
        // console.log("partner",partner)
        
    }, []);

    //  console.log("business search", secUser)

    useEffect(() => {
        //  console.log("secUserId >>>>", secUser)
        if (Object.keys(secUser).length === 0 ) {

        } else { 
            if(secUser.useFlg === 0) {
                setUseflg(true);
                setUseflgData(secUser.useFlg)
            }   
            
            console.log("secUser",secUser.email)
            
            form.setFieldsValue({

                id: secUser.id,
                fullname: secUser.fullname,
                username: secUser.username,
                password: secUser.password,
                comfirmPassword: secUser.password,
                email: secUser.email,
                userUId: secUser.userUId,
                tel: secUser.tel,
                // custUId: secUser.custUId,
                
                userIdLogin: userId,
                usernameLogin: username,

            });

            
        }
    }, [secUser]);
   
    const onCheckUsername = (data: any) => {
        let checkSecuser =  partnerService.checkSecUserByUsername(data);
        if(checkSecuser !== null && checkSecuser!== undefined){
            setCheckUsername(false);
        }
    };


    const onFinish = async (values: any) => {

         const secUser_: SecUser = {

            id: secUser.id,
            fullname: values.fullname,
            username: values.username,
            password: values.password,
            email: values.email,
            userUId: secUser.userUId,
            tel: partner.tel,
            useFlg : useflgData,
            // custUId: business.custUId,

            userIdLogin: userId,
            usernameLogin: username,
            savStation : station,
        };

        // if (typeof (secUser_.id) === "undefined" || secUser_.id === 0) {
        console.log(secUser)
        if ( Object.keys(secUser).length === 0) {
            try {
                partnerService.addSecUser(partnerUId ,secUser_).then(res => {
                    message.success("save success", 1.5);
                }).catch(err => {
                    console.log(err);
                });
            } catch (err:any) {
                showMessage(err);
            }
        }

        if (Object.keys(secUser).length !== 0) {
            try {
                partnerService.updateUser(partnerUId,secUser_).then(res => {
                    message.success("update success", 1.5);
                }).catch(err => {
                    console.log(err);
                });
            } catch (err:any) {
                showMessage(err);
            }
          
        }
        // setTrigger(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
          <Form
                                    form={form}
                                    layout="horizontal"
                                    onFinish={onFinish}
                                     onFinishFailed={onFinishFailed}
                                // initialValues={{ requiredMarkValue: requiredMark }}
                                // onValuesChange={onRequiredTypeChange}
                                // requiredMark={requiredMark}
                                >
                         
                                    <Row>
                                        <Col span={24}>
                                            <div
                                                className={classes.divOwnerUser}
                                            >
                                                <Row >
                                                    <Col span={14} >
                                                    <Row gutter={10} style={{ marginTop: "5em", }}>
                                                            <Col span={7} style={{ textAlign: "right", fontSize: "15px" }}>
                                                                <span>ชื่อผู้ใช้ :</span> </Col>
                                                            <Col span={12} >
                                                                <Form.Item
                                                                    name="fullname"
                                                                    rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
                                                                    required tooltip="This is a required field">
                                                                    <Input placeholder="" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={4}
                                                            // style={{ border: "1px solid red" }}
                                                            ></Col>
                                                        </Row>
                                                        <Row  gutter={10}>
                                                            <Col span={7} style={{ textAlign: "right", fontSize: "15px" }}>
                                                                <span>Email :</span> </Col>
                                                            <Col span={12} >
                                                                <Form.Item
                                                                    name="email"
                                                                    rules={[{ type: 'email', required: true, message: 'กรุณากรอกอีเมล์' }]}
                                                                    required tooltip="This is a required field">
                                                                    <Input placeholder="" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={4}
                                                            // style={{ border: "1px solid red" }}
                                                            ></Col>
                                                        </Row>

                                                        <Row gutter={10}>
                                                            <Col span={7} style={{ textAlign: "right", fontSize: "15px" }}>
                                                                <span>Username :</span> </Col>
                                                            <Col span={12} >
                                                                <Form.Item
                                                                    name="username"
                                                                    rules={[{ required: true, message: 'กรุณากรอก Username' }]}
                                                                    required tooltip="This is a required field">
                                                                    <Input placeholder="" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={4}
                                                            // style={{ border: "1px solid red" }}
                                                            ></Col>
                                                        </Row>
                                                        <Row gutter={10}>
                                                            <Col span={7} style={{ textAlign: "right", fontSize: "15px" }}>
                                                                <span>Password :</span> </Col>
                                                            <Col span={12} >
                                                            <Form.Item
                                        name="password"
                                        // label="Password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอก password!',
                                            },
                                            ]}
                                         hasFeedback
                                    >
                                    <Input.Password />
                                    </Form.Item>
                                                            </Col>
                                                            <Col span={4}
                                                            // style={{ border: "1px solid red" }}
                                                            ></Col>
                                                        </Row>
                                                        <Row gutter={10}>
                                                            <Col span={7} style={{ textAlign: "right", fontSize: "15px" }}>
                                                                <span>Confirm Password :</span> </Col>
                                                            <Col span={12} >
                                                                {/* <Form.Item
                                                                    name="comfirmPassword"
                                                                    required tooltip="This is a required field">
                                                                    <Input placeholder="" />
                                                                </Form.Item> */}
                                                                <Form.Item
        name="comfirmPassword"
        // label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'กรุณายืนยัน password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('password ไม่ตรงกัน'));
            },
          }),
        ]}
      >
      <Input.Password />
      </Form.Item>
                                                            </Col>
                                                            <Col span={4}
                                                            // style={{ border: "1px solid red" }}
                                                            ></Col>
                                                        </Row>

                                                    </Col>

                                                    <Col span={1}></Col>

                                                    <Col span={7}>

                                                        <Row
                                                            style={{
                                                                marginBottom: "2em",
                                                                //    border: "1px solid red"
                                                            }}
                                                        >
                                                            <Col span={12}></Col>
                                                            <Col  >

                                                                <Button
                                                                    size="middle"
                                                                    type="primary"
                                                                    icon={<SaveOutlined />}
                                                                    onClick={() => { form.submit() }}
                                                                >
                                                                    เพิ่ม
                                                                </Button>
                                                            </Col>

                                                            <Col span={1}></Col>
                                                        </Row>

                                                        <Row className={classes.imgOwnerUser} >
                                                            <Col span={1}></Col>
                                                            <Col span={18}
                                                                style={{
                                                                    //alignItems: "center" ,
                                                                    height: "200px",
                                                                    //  border: "1px solid red"
                                                                }}


                                                            >
                                                                {/* <Form.Item required tooltip="This is a required field"> */}
                                                                <Image
                                                                    width="100%"
                                                                    height={200}
                                                                    src="https://entertain.teenee.com/kpop/img6/1300053.jpg" />
                                                                {/* </Form.Item> */}

                                                            </Col>
                                                            <Col span={1}></Col>
                                                        </Row>
                                                        <Row style={{ marginTop: "1em" }}>
                                                            <Col span={24}>

                                                                <Row>

                                                                    <Col span={7} style={{ textAlign: "right" }}>
                                                                        <span >รหัส :</span>
                                                                    </Col>
                                                                    <Col span={1}></Col>
                                                                    <Col span={14}>

                                                                        <span >{partnerUId}</span>
                                                                    </Col>
                                                                    <Col span={2}></Col>
                                                                </Row>
                                                                <Row style={{ marginTop: "1em", marginBottom: "1em" }}>

                                                                    <Col span={7} style={{ textAlign: "right" }}>
                                                                        <span>สถานะ :</span>
                                                                    </Col>
                                                                    <Col span={1}></Col>
                                                                    <Col span={14} >
                                                                        <Switch onChange={switchOnChange} checked={isUseflg} />
                                                                    </Col>
                                                                    <Col span={2}></Col>
                                                                </Row>

                                                            </Col>

                                                        </Row>



                                                        <Row style={{ marginBottom: "2em" }}>


                                                            <Col span={1}></Col>
                                                        </Row>

                                                    </Col>


                                                </Row>

                                            </div>

                                        </Col>

                                    </Row>          

                                </Form>  
        </>
    )
}

export default PartnerUserInfo
