import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Col, Form, Input, message, Row, Typography } from "antd";

import { Post } from "src/domain/postType";
import TextArea from "antd/lib/input/TextArea";
import { showMessage } from "src/utils";
import { dummydata } from "./data";
import { Subject } from "src/domain/subjectType";
import subjectService from "src/services/subjectService";

interface IProps {
  id: number;
  trigger: boolean;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 20 },
};



const SubjectForm: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  console.log(id)
  const [subject, setSubject] = useState<Subject>({} as Subject);
  const history = useHistory();
  const { Title } = Typography;
  const [form] = Form.useForm();


  useEffect(() => {
    const res =  subjectService.getSubjectById(Number(id));
    // console.log(res);
    setSubject(res);
  }, []);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);


  useEffect(() => {
    console.log(subject)
    if(id === 0){

    }else {
      form.setFieldsValue({
        id: subject.id,
        name: subject.name,
        unit: subject.unit,
        level: subject.level,
      });
    }
    
  }, [subject]);

  const onFinish = async (values: any) => {
    console.log(id)
    console.log("Success:", values);
    // let genId = Math.random();
    // console.log(genId)
    const subject_: Subject = {
      id: id,
      name: values.name,
      unit: values.unit,
      level: values.level,
    };
    

    if (id === 0) {
      try {
        // console.log(id)
        const res = await subjectService.addSubject(subject_);
        console.log(res);
        history.goBack()
        // history.replace("/app/subject/view/" + id);
        message.success("Update Complete", 1.5);
      } catch (err) {
        showMessage(err);
      }
    }

    if (id !== 0) {
      try {
        const res = await subjectService.updateSubject(subject_);
        console.log(res);
        history.replace("/app/subject/view/" + id);
        message.success("Update Complete", 1.5);
      } catch (err) {
        showMessage(err);
      }
    }
    setTrigger(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  

  // const loadUser = async () => {
  //   // console.log(">>>>>>>>"+id);
  //   const res:any = await subjectService.getSubjectById(Number(id));
  //   console.log(res);
  //   setSubject(res);
  // };

  
  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row style={{ marginBottom: "3em" }}>
          <Col span={24} style={{ marginTop: "2em" }}>
            <Form.Item
              wrapperCol={{ offset: 6, span: 12 }}
              // rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Typography.Title level={3}>Subject</Typography.Title>
            </Form.Item>

            <Form.Item
              label="Code"
              name="id"
              rules={[
              { required: true, message: "Please input your Code!" },
              // {type:"number"}
            ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Subject Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Subject name!" },
                {
                  min: 5,
                  message: "Please input your body at least 5 character",
                },
              ]}
            >
              <TextArea rows={5} autoSize />
            </Form.Item>

            <Form.Item
              label="Unit"
              name="unit"
              rules={[{ required: true, message: "Please input your unit!" }]}
            >
              <TextArea rows={5} autoSize />
            </Form.Item>

            <Form.Item
              label="Level"
              name="level"
              rules={[{ required: true, message: "Please input your level!" }]}
            >
              <TextArea rows={5} autoSize />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SubjectForm;
