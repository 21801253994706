import React, { MouseEventHandler } from "react";

type justifyType = "center" | "start" | "end" | "between" | "around";
type alignItemsType = "center" | "start" | "end";
type flexDirectionType = "row" | "column";

interface IProps {
  className?: string;
  alignItems?: alignItemsType;
  justifyContent?: justifyType;
  children?: React.ReactNode;
  header?: string | Function | JSX.Element;
  headerClassName?: string;
  containerStyle?: any;
  tooltip?: string;
  onClick?: Function;
}

const GroupContainer: React.FC<IProps> = ({
  className,
  alignItems = "center",
  justifyContent = "start",
  children,
  header,
  containerStyle,
  tooltip,
  onClick
}) => {
  return (
    <div
      className={`d-flex ${className ? className : ""} flex-column 
        ${alignItems ? "align-items-" + alignItems : ""} ${
        justifyContent ? "justify-content-" + justifyContent : ""
      }`}
      style={{ width: "100%", cursor: onClick == null ? 'default' : 'pointer' }}
      title={tooltip}
      onClick={onClick == null ? () => {} : () => {onClick();}}
    >
        <div className={`d-flex ${className ? className : ""}`}
            style={{width: '90%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F8F8F8', padding: '5px 0px 5px 0px', margin: '5px 0px 5px 0px', borderRadius: 5, ...containerStyle}}>
            {header}
        </div>
        {children}
    </div>
  );
};

export default GroupContainer;
