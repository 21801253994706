import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";

import PageHeader from "src/layouts/PageHeader";

import { Form, Button, Space } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { Content } from "antd/lib/layout/layout";

import { Teacher } from "src/domain/teacherType";
import TeacherForm from "./TeacherForm";

interface IParams {
  id: string;
}

const EditUser: React.FC<RouteComponentProps<IParams>> = (props) => {
  const history = useHistory();
  const [trigger, setTrigger] = useState<boolean>(false);
  // const [form] = Form.useForm();
  const id: number = Number(props.match.params.id);

  return (
    <>
      <PageHeader
        title="Edit Teacher"
      >
        <Space>
          <Button
            danger
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => setTrigger(true)}
          >
            Save
          </Button>
          
          <Button
            size="small"
            // type="primary"
            icon={<CloseCircleOutlined />}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Space>
      </PageHeader>
      <Content className="app-page-content">
        <TeacherForm id={id} trigger={trigger} setTrigger={setTrigger} />
      </Content>
    </>
  );
};

export default EditUser;
