import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import teacherService from "../../services/teacher/teacherService";
import { Teacher } from "src/domain/teacherType";
import { Button, Descriptions } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

interface IParams {
  id: string;
}



const ViewTeacher: React.FC<RouteComponentProps<IParams>> = (props) => {
  const [teacher, setTeacher] = useState<Teacher>({} as Teacher);
  const history = useHistory();

  const handleDelete = (Teacher: any) => {
    console.log(" Teacher :", Teacher);
    teacherService.deleteTeacher(Number(Teacher.id));
    history.goBack();
  };
  
  useEffect(() => {
    let id: number = Number(props.match.params.id);
    const data = teacherService
    .getTeacherById(id);
    setTeacher( data[0]);
      // .then((res) => {
      //   console.log(res.data);
      //   setTeacher(res.data);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
  }, []);

  // const loadUser = () => {};

  return (
    <>
      <PageHeader
        title={"Teacher: " + teacher.name}
        onClose={() => history.push("/app/teacher/list")}
      >
        <Button
          size="small"
          // type="default"
          icon={<EditOutlined />}
          onClick={() => history.push(`/app/teacher/edit/${teacher.id}`)}
        >
          Edit
        </Button>

        <Button
          size="small"
          // type="default"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(teacher)}
        >
          Delete
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div style={{ padding: "0px 20px" }}>
          <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            title="Genral Information"
            size="middle"
            bordered
          >
            <Descriptions.Item label="Name" span={2}>
              {teacher.name}
            </Descriptions.Item>
            <Descriptions.Item label="Lastname">
              {teacher.lastname}
            </Descriptions.Item>
            <Descriptions.Item label="Gender">{teacher.gender}</Descriptions.Item>
            <Descriptions.Item label="Education">{teacher.education}</Descriptions.Item>
          </Descriptions>
          
        </div>
      </Content>
    </>
  );
};

export default ViewTeacher;
