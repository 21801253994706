
import { Button, Descriptions, Divider, Image, Input, Radio, Table, Tag, Avatar, Tooltip, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { useHistory } from 'react-router';
import { Truck } from 'src/domain/truckDataType';
import classes from "../owner/master.module.css";

import truckService from "src/services/truckService";
import TruckDataInfo from './TruckDataInfo';
import { Link } from 'react-router-dom';


const { Search } = Input;



const data: Truck[] = [
  {
    id: 1,
    registId: 'ชม 70-1234',
    driverName: 'win win',
    truckTypeName: '10 ล้อ',
    useFlgStr: 'ใช้งาน',
  },
  {
    id: 2,
    registId: 'ชม 70-8877',
    driverName: 'mark jin',
    truckTypeName: '12 ล้อ',
    useFlgStr: 'ใช้งาน',
  },
  {
    id: 3,
    registId: 'ชม 70-9922',
    driverName: 'tin tin',
    truckTypeName: 'พ่วงลูก',
    useFlgStr: 'ใช้งาน',
  },
  {
    id: 4,
    registId: 'ชม 70-0566',
    driverName: 'key woo',
    truckTypeName: '10ล้อพ่วงแม่',
    useFlgStr: 'ใช้งาน',
  },
];

const columns = [

  {

    title: 'ทะเบียนรถ',
    align: 'center' as 'center',
    dataIndex: 'registId',
    render: (value: string, item: Truck, index: number) => <Link to={`/app/truck/edit/${item.id}`}>{value}</Link>,

  },
  {
    title: 'ชนิดรถ',
    dataIndex: 'truckTypeName',
    //   align: 'right' as 'right',
    render: (text: any, record: Truck) => {
      return (
        <>
          <Tooltip title={record.truckTypeName} placement="top">
            <Avatar
              src={<Image src="https://www.truckmarketthailand.com/asset/images/truck/10wheel.png" />}
            />
          </Tooltip>

        </>
      )
    },
  },
  {
    title: 'พนักงานขับรถ',
    dataIndex: 'driverName',
  },

  {
    title: 'สถานะ',
    key: 'useFlg',
    dataIndex: 'useFlg',
    render: (useFlg: number) => (
      <>
       <div>
          {(() => {
            if (useFlg ===  0 ) {
              return (
                <Tag color="green">{"ใช้งาน"}</Tag>
              )
            } else {
              return (
                  <Tag color="red">{"ไม่ใช้งาน"}</Tag>
              )
            }
          })()}
        </div>
        {/* <div>
          {(() => {
            if (useFlgStr === 'ใช้งาน') {
              return (
                <div style={{
                  border: "1px solid  #2E8B57",
                  textAlign: "center", background: "#CCFFCC", width: "100px", height: "30px"
                }}>
                  <span style={{
                    verticalAlign: "middle", color: "#2E8B57",
                  }}>{useFlgStr}</span>
                </div>
              )
            } else {
              return (
                <div style={{ background: "red" }}>
                  <span style={{ color: "#ffff" }}>{useFlgStr}</span>
                </div>
              )
            }
          })()}
        </div> */}
        {/* let color =  'green' ;
           if (tag === 'ไม่ใช้งาน') {
             color = 'volcano';
           }
           return (
             <Tag color={color} key={tag}>
               {tag.toUpperCase()}
             </Tag>
           ); */}

        {/* <div  style={{ background: "green" }}>
          <span  style={{ color: "#ffff" }}>{useFlgStr}</span>
        </div> */}

        {/* {isLoggedIn ? 'currently' : 'not'} */}
      </>
    ),
    // render: (status:any) => (
    //     <>
    //     {
    //     status.map((tag:any) => {
    //       let color =  'green' ;
    //       if (tag === 'ไม่ใช้งาน') {
    //         color = 'volcano';
    //       }
    //       return (
    //         <Tag color={color} key={tag}>
    //           {tag.toUpperCase()}
    //         </Tag>
    //       );
    //     }

    //     )
    //     }
    //   </>
    // ),
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: Truck[]) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  // getCheckboxProps: (record: DataType) => ({
  //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
  //   name: record.name,
  // }),
};

const TruckList = () => {

  const history = useHistory();
  const onSearch = () => { handleSearch() };
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');


  // const history = useHistory();
  // const { Content } = Layout;
  const [truck, setTruck] = useState([])
  const [searchData, setSearchData] = useState<any>("");
  const [isModalVisible,setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {

    truckService.getAllTruck()
      .then((res) => {
        console.log(res.data)
        setTruck(res.data)
      })

  }, []);

  const handleSearch = () => {
    console.log(searchData,)

    truckService.getTruckByCondition(searchData)
      .then((res) => {
        console.log(res.data)
        setTruck(res.data)
      })
  }

  const showModal = () => {
    setIsModalVisible(true);
};

const handleOk = () => {
    setIsModalVisible(false);
};

const handleCancel = () => {
    setIsModalVisible(false);
};

const handleAdd = () => {
  
  //setIsModalVisible(false);
};

  return (
    <>
      <PageHeader title="ข้อมูลรถ">

        <Button
          size="small"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
          // onClick={() => history.push("/app/truck/new")}
        >
          เพิ่ม
           </Button>

      </PageHeader>

      <Content className="app-page-content" >

        <div className={classes.divList}>

          <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200 }} />


          <div style={{

            padding: "20px",
            width: "100%",

          }}>

            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              //  columns={columns}
              //  dataSource={data} 

              rowKey={(item: Truck) => item.id}
              columns={columns}
              dataSource={truck}

            // size="small"
            // rowKey={(item: Post) => item.id}
            />
          </div>

          {/* </Input.Group> */}


        </div>

      </Content>

      <Modal title="เพิ่มข้อมูล คู่ค้า "
                visible={isModalVisible}
                footer={null}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
            >
                <div>
                    <TruckDataInfo
                        id={0}
                        userId={0}
                        username={"sittiponn.a"}
                        station={"11"}
                        isSave={() => {
                            // console.log("onNextStep");
                            handleCancel();
                        }}
                    >

                    </TruckDataInfo>
                </div>
            </Modal>
    </>
  )
}

export default TruckList

