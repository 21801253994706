import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import AppLayout from "src/layouts/AppLayout";
import ProductForm from "./sample/ProductForm";
import ProductList from "./sample/ProductList";
import Dashboard from "./Dashboard";
import ListUser from "./users/ListUser";
import NewUser from "./users/NewUser";
import ViewUser from "./users/ViewUser";
import EditUser from "./users/EditUser";
import ListPost from "./posts/ListPosts";
import ViewPost from "./posts/ViewPost";
import EditPost from "./posts/EditPost";
import NewPost from "./posts/NewPost";
import CountList from "./counter";
import Regex from "./Regex";
import ListTeacher from "./teacher/ListTeacher";
import NewTeacher from "./teacher/NewTeacher";
import ViewTeacher from "./teacher/ViewTeacher";
import EditTeacher from "./teacher/EditTeacher";
import StudentList from "./student/StudentList";
import NewStudent from "./student/NewStudent";
import EditStudent from "./student/EditStudent";
import ViewStudent from "./student/ViewStudent";

import ListSubject from "./subject/ListSubject";
import NewSubject from "./subject/NewSubject";
import ViewSubject from "./subject/ViewSubject";
import EditSubject from "./subject/EditSubject";

import ListOfficer from "./officer/ListOfficer";
import ViewOfficer from "./officer/ViewOfficer";
import EditOfficer from "./officer/EditOfficer";
import NewOfficer from "./officer/NewOfficer";
import OwnerAdd from "./owner/OwnerAdd";
import OwnerList from "./owner/OwnerList";
import TruckAdd from "./truck/TruckAdd";
import TruckList from "./truck/TruckList";
import DriverAdd from "./driver/DriverAdd";
import DriverList from "./driver/DriverList";
import TruckEdit from "./truck/TruckEdit";

import FormPayment from "./payment/FormPayment";
import PartnerForm from "./partner/PartnerForm";
import PartnerList from "./partner/PartnerList";
import TruckForm from "./truck/TruckForm";


import TruckMatchingList from "./truckmatching/TruckMatchingList";
import TruckMatchingManage from "./truckmatching/TruckMatchingManage";
import TruckMatchingSendRequest from "./truckmatching/TruckMatchingSendRequest";

import JobListMain from "./sendjob2partner/JobListMain";
import SelectPartner from "./sendjob2partner/SelectPartner";
import ViewJobPartnerDetail from "./sendjob2partner/ViewJobPartnerDetail";
import BusinessForm from "./Business/BusinessForm";
import RegistBusinessList from "./Business/RegistBusinessList";
import RegistBusinessForm from "./Business/RegistBusinessForm";
import mail from "./test/mail";
import PartnerAvatar from "./partner/PartnerAvatar";
import AuthGuard from "src/components/AuthGuard";
import ChangePassword from "./secUser/ChangePassword";
import JobRengerMoveList from "./jobrenger/JobRengerMoveList";
import  SearchDatePopup  from "./jobrenger/SearchDatePopup";
import AddJobRengerMove from "./jobrenger/AddJobRengerMove";


const AppRoute: React.FC<RouteComponentProps> = (props) => {
  // console.log(props);
  return (
    <AuthGuard>
    <AppLayout>
      <Switch>
        {/* dashboard */}
        <Route exact path="/app/dashboard" component={Dashboard} />

        {/* product s*/}
        <Route exact path="/app/products/view/:id" component={ProductForm} />
        <Route exact path="/app/products/edit/:id" component={ProductForm} />
        <Route exact path="/app/products/new" component={ProductForm} />
        <Route exact path="/app/products/list" component={ProductList} />
        <Redirect path="/app/products" to="/app/products/list" />

        {/* user */}
        <Route exact path="/app/users/list" component={ListUser} />
        <Route exact path="/app/users/new" component={NewUser} />
        <Route exact path="/app/users/view/:id" component={ViewUser} />
        <Route exact path="/app/users/edit/:id" component={EditUser} />
        <Redirect path="/app/users" to="/app/users/list" />

        {/* post */}
        <Route exact path="/app/posts/list" component={ListPost} />
        <Route exact path="/app/posts/new" component={NewPost} />
        <Route exact path="/app/posts/view/:id" component={ViewPost} />
        <Route exact path="/app/posts/edit/:id" component={EditPost} />
        <Redirect path="/app/posts" to="/app/posts/list" />

        {/* counter */}
        <Route exact path="/app/counter" component={CountList} />

        {/* teacher */}
        <Route exact path="/app/teacher/list" component={ListTeacher} />
        <Route exact path="/app/teacher/new" component={NewTeacher} />
        <Route exact path="/app/teacher/view/:id" component={ViewTeacher} />
        <Route exact path="/app/teacher/edit/:id" component={EditTeacher} />
        <Redirect path="/app/teacher" to="/app/teacher/list" />
        {/* student */}
        <Route exact path="/app/student/list" component={StudentList} />
        <Route exact path="/app/student/new" component={NewStudent} />
        <Route exact path="/app/student/edit/:id" component={EditStudent} />
        <Route exact path="/app/student/view/:id" component={ViewStudent} />
        <Redirect path="/app/student" to="/app/student/list" />

        {/* subject */}
        <Route exact path="/app/subject/list" component={ListSubject} />
        <Route exact path="/app/subject/new" component={NewSubject} />
        <Route exact path="/app/subject/view/:id" component={ViewSubject} />
        <Route exact path="/app/subject/edit/:id" component={EditSubject} />
        <Redirect path="/app/subject" to="/app/subject/list" />

        {/* officer */}
        <Route exact path="/app/officers/new" component={NewOfficer} />
        <Route exact path="/app/officers/list" component={ListOfficer} />
        <Route exact path="/app/officers/edit/:id" component={EditOfficer} />
        <Route exact path="/app/officers/view/:id" component={ViewOfficer} />
        <Redirect path="/app/officers" to="/app/officers/list" />


        {/* matching management */}
        <Route exact path="/app/matching/list" component={TruckMatchingList} />
        <Route exact path="/app/matching/manage/:selectedDate/:truckTypeId" component={TruckMatchingManage} />
        <Route exact path="/app/matching/manage/sendRequest" component={TruckMatchingSendRequest} />
        
        {/* send job to partner */}
        <Route exact path="/app/send2partner/list" component={JobListMain} />
        <Route exact path="/app/send2partner/selectpartner" component={SelectPartner} />
        <Route exact path="/app/send2partner/view" component={ViewJobPartnerDetail} />
        <Redirect path="/app/send2partner" to="/app/send2partner/list" />


        {/* owner */}
        {/* <Route exact path="/app/owner/new" component={OwnerAdd} /> */}
        {/* <Route exact path="/app/owner/list" component={OwnerList} /> */}
        {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
        <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
        {/* <Redirect path="/app/owner" to="/app/owner/list" /> */}

         {/* owner */}
        <Route exact path="/app/partner/new" component={PartnerForm} />
        <Route exact path="/app/partner/list" component={PartnerList} />
        <Route exact path="/app/partner/edit/:partnerUId" component={PartnerForm} />
        <Route exact path="/app/partner/partnerAvatar" component={PartnerAvatar} />
        {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
        <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
        <Redirect path="/app/partner" to="/app/partner/list" />

        {/* truck */}
        <Route exact path="/app/truck/new" component={TruckForm} />
        <Route exact path="/app/truck/edit/:id" component={TruckForm} />
        <Route exact path="/app/truck/list" component={TruckList} />
        {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
        <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
        <Redirect path="/app/truck" to="/app/truck/list" />

        {/* driver */}
        <Route exact path="/app/driver/new" component={DriverAdd} />
        <Route exact path="/app/driver/list" component={DriverList} />
        {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
        <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
        <Redirect path="/app/driver" to="/app/driver/list" />


        {/* business */}
        {/* <Route exact path="/app/business/new/:id" component={RegistBusinessForm} /> */}
        <Route exact path="/app/business/new/:id" component={BusinessForm} />
        <Route exact path="/app/business/list" component={RegistBusinessList} />
        <Route exact path="/app/business/edit/:id" component={RegistBusinessForm} />
        {/*<Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
        <Route exact path="/app/business/payment" component={FormPayment} />
        <Redirect path="/app/business" to="/app/business/list" />

         {/* jobrenger */} 
         <Route exact path="/app/jobrenger/list" component={JobRengerMoveList} />
         <Route exact path="/app/jobrenger/form/:jobType" component={AddJobRengerMove} />
         <Route exact path="/app/jobrenger/view/:jobNo" component={AddJobRengerMove} />
         <Redirect path="/app/jobrenger" to="/app/jobrenger/list" />

        {/* secuser */}
        <Route exact path="/app/secuser/changepassword" component={ChangePassword} /> 

        {/*test*/}
        <Route exact path="/app/test/mail" component={mail} />
        {/* regex */}
        <Route exact path="/app/regex" component={Regex} />

        {/* other */}
        <Redirect path="/app" to="/app/dashboard" />
      </Switch>
    </AppLayout>
    </AuthGuard>
  );
};

export default AppRoute;
