import React from 'react'
//  import sendmail from './sendmail';

 var nodemailer = require("nodemailer");

    

 function SendIt() {
   var transporter = nodemailer.createTransport({
     service: "gmail",
     auth: {
       user: "sittiponn.api@gmail.com",
       pass: "genie719922",
     },
   });
 
   const mailOptions = {
     from: "sittiponn.api@gmail.com", 
     to: "sittiponna22@gmail.com",
     subject: "Subject of your email",
     html: "<p>Your html here</p>",
   };
 
   console.log("test")

   transporter.sendMail(mailOptions, function (err:any, info:any) {

    console.log("sssssssss")

     if (err) console.log(err);
     else console.log(info);
   });
 }
 
//  electron.ipcMain.on("SendIt", (event, args) => {
//    console.log("ipcMain: Executing SendIt");
//    SendIt();
//  });

const mail = () => {
  return (
    <div>
        <button type="submit" className="primary-btn submit" onClick={() => SendIt}>Submit</button> 
    </div>
  )
}

export default mail